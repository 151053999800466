<script>
export default {}
</script>

<template>
  <div class="footer">
    <p class="footer__note">*Мається на увазі наявність гетерологічної резистентності до певних груп антибіотиків
      (детальна інформація зазначена в інструкції).</p>
    <p class="footer__note footer__note_mb">**Якщо препарат призначати одночасно з антибіотиками, його слід
      застосовувати між двома прийомами антибіотика.</p>
    <ol class="footer__note-links">
      <li class="footer__note-link-item">1. Інструкція для медичного застосування лікарського засобу
        Ентерожерміна<sup>®</sup>
        Форте, суспензія оральна. РП <span>№</span>UA/15608/01/01.
      </li>
      <li class="footer__note-link-item">2. Jandhyala S.M., Talukdar R., Subramanyam C. et al. Role of the normal gut
        microbiota. World J Gastroenterol. 2015; 21(29):8787-803. DOI: 10.3748/wjg.v21.i29.8787
      </li>
      <li class="footer__note-link-item">3. Rogers M.A.M., Aronoff D.M. The Influence of Nonsteroidal Anti-Inflammatory
        Drugs on the Gut Microbiome. Clin Microbiol Infect. 2016; 22(2):178.e1–178.e9. DOI: 10.1016/j.cmi.2015.10.003
      </li>
      <li class="footer__note-link-item">4. Zhang P. Influence of Foods and Nutrition on the Gut Microbiome and
        Implications for Intestinal Health. Int J Mol Sci. 2022; 23(17):9588. DOI: 10.3390/ijms23179588
      </li>
      <li class="footer__note-link-item">5. Matsuyama M. et al. Dietary intake influences gut microbiota development of
        healthy Australian children from the age of one to two years. Scientific Reports. 2019; 9:12476.
        <a href="https://doi.org/10.1038/s41598-019-48658-4"
           target="_blank">https://doi.org/10.1038/s41598-019-48658-4</a>
      </li>
      <li class="footer__note-link-item">6. Madison A., Kiecolt-Glaser J.K. Stress, depression, diet, and the gut
        microbiota: human–bacteria interactions at the core of psychoneuroimmunology and nutrition. Curr Opin Behav Sci.
        2019; 28:105–110. DOI: 10.1016/j.cobeha.2019.01.011
      </li>
      <li class="footer__note-link-item">7. How is traveler’s diarrhea diagnosed? Available at:
        <a href="https://www.healthline.com/health/travelers-diarrhea#diagnosis" target="_blank">https://www.healthline.com/health/travelers-diarrhea#diagnosis</a>
        (Дата перегляду: 26.03.2024).
      </li>
      <li class="footer__note-link-item">8. Інструкція для медичного застосування лікарського засобу
        Ентерожерміна<sup>®</sup>,
        суспензія оральна. РП <span>№</span>UA/4234/01/01.
      </li>
      <li class="footer__note-link-item">9. Інструкція для медичного застосування лікарського засобу
        Ентерожерміна<sup>®</sup>,
        капсули. РП <span>№</span>UA/4234/02/01.
      </li>
    </ol>
    <p class="footer__disclaimer">Реклама лікарського засобу. Перед застосуванням необхідно проконсультуватися з лікарем
      та обов'язково ознайомитися з інструкцією для медичного застосування на лікарський засіб.<br>Зберігати в
      недоступному
      для дітей місці.</p>
    <p class="footer__links">
      <a href="https://enterogermina.ua/" target="_blank" class="footer__link">www.enterogermina.ua, </a>
      <a href="https://www.sanofi.com/uk/ukraine" target="_blank" class="footer__link">www.sanofi.ua</a>
    </p>
    <div class="footer__reg-info">
      <p class="footer__reg-number">MAT-UA-2400754-1.0-09/2024</p>
      <a href="https://www.sanofi.com/uk/ukraine" class="footer__logo-link" target="_blank">
        <img class="footer__logo" src="@/assets/img/advertising/sanofi-logo.svg" alt="sanofi-logo"/>
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer {
  padding: 70px 70px 94px 70px;

  max-width: 1440px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    padding: 20px 20px 20px 20px;

  }

  &__note {
    align-self: stretch;
    color: #29343D;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    @media screen and (max-width: 600px) {
      font-size: 12px;
    }

    &-links {
      margin-bottom: 16px;
      @media screen and (max-width: 600px) {
        margin-bottom: 8px;
      }
    }

    &-link {

      &-item {
        align-self: stretch;

        color: #29343D;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;

        span {
          font-weight: 300;
        }

        @media screen and (max-width: 600px) {
          font-size: 12px;
        }

        a {
          align-self: stretch;

          color: #29343D;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 160%;
          text-decoration: none;

          word-wrap: break-word;

          @media screen and (min-width: 1024px) {
            &:hover {
              text-decoration: underline;
            }
          }

          &:active {
            text-decoration: underline;
          }

          @media screen and (max-width: 600px) {
            font-size: 12px;
          }
        }

      }
    }

    &_mb {
      margin-bottom: 16px;
    }
  }

  &__disclaimer {
    color: #29343D;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  &__links {
    margin-bottom: 8px;
  }

  &__link {
    color: #29343D;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    text-decoration: none;

    @media screen and (max-width: 600px) {
      font-size: 12px;
    }


  }

  &__reg-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__reg-number {
    color: #29343D;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    @media screen and (max-width: 600px) {
      font-size: 12px;
    }

  }

  &__logo-link {
    text-decoration: none;
  }

  &__logo {
    width: 151px;
    height: auto;
    display: block;
    @media screen and (max-width: 600px) {
      max-width: 100px;
      width: 100%;
    }
  }
}
</style>