<script>
export default {
  mounted() {

    let constrain = 100;
    let mouseOverContainer = document.getElementById("animation-wrapper2");
    let ex1Layer = document.getElementById("mascot3");

    function transforms(x, y, el) {
      let box = el.getBoundingClientRect();
      let calcX = -(y - box.y - (box.height / 2)) / constrain;
      let calcY = (x - box.x - (box.width / 2)) / constrain;

      return "perspective(100px) "
          + "   rotateX(" + calcX + "deg) "
          + "   rotateY(" + calcY + "deg) ";
    }

    function transformElement(el, xyEl) {
      el.style.transform = transforms.apply(null, xyEl);
    }

    const mql = window.matchMedia('(min-width: 1240px)');

    if (mql.matches) {
      mouseOverContainer.onmousemove = function (e) {
        let xy = [e.clientX, e.clientY];
        let position = xy.concat([ex1Layer]);

        window.requestAnimationFrame(function () {
          transformElement(ex1Layer, position);
        });
      };
    }
  }
}
</script>

<template>
  <div class="content" id="animation-wrapper2">

    <svg class="content__top-img" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
         data-bbox="-77.001 0.338 1544.001 122.662" viewBox="0 0 1440 93" height="100%" width="100%" data-type="ugc">

      <g>
        <g clip-path="url(#4ef0e7a0-d9b7-4cb3-97aa-e31ef54b3473)">
          <path fill="#ffffff"
                d="M1054 77c203.47-3.535 413-51.5 413-51.5V123H-9.391l-67.61-93S50.5 132 374.5 35 795 81.5 1054 77Z"/>
          <path fill="url(#1160729b-0a72-482a-b0dd-5ff0cf631414)"
                d="M1466.87 24.57s-209.54 47.966-413 51.5c-100.648 1.75-176.754-18.85-251.206-39-117.147-31.708-230.2-62.307-428.299-3C141.224 103.87 9.827 70.63-45.533 46.498l-.45 25.426 6.853 9.426c186.87 20.63 213.093 13.876 360.468-24.08 16.796-4.326 35.166-9.057 55.527-14.199 179.483-45.328 356.841-5.972 486.846 22.877 11.964 2.654 23.527 5.22 34.654 7.623 167.065 36.075 356.255 14.236 514.665-4.05 18.39-2.122 36.36-4.197 53.84-6.127V24.57Z"/>
        </g>
        <defs fill="none">
          <linearGradient gradientUnits="userSpaceOnUse" y2="-544.461" x2="1209.5" y1="129" x1="1635.5"
                          id="1160729b-0a72-482a-b0dd-5ff0cf631414">
            <stop stop-color="#00BAB3"/>
            <stop stop-color="#73C5EC" offset=".649"/>
            <stop stop-color="#1FA2EC" offset=".981"/>
          </linearGradient>
          <clipPath id="4ef0e7a0-d9b7-4cb3-97aa-e31ef54b3473">
            <path transform="matrix(1 0 0 -1 0 93)" fill="#ffffff" d="M1440 0v93H0V0h1440z"/>
          </clipPath>
        </defs>
      </g>
    </svg>


    <div class="content__col content__col--image">
      <picture>
        <source srcset="@/assets/img/advertising/section3/circle.webp" type="image/webp">
        <img class="content__circle js-scroll opacity-scale" src="@/assets/img/advertising/section3/circle.png"
             alt="happy family">
      </picture>

      <picture>
        <source srcset="@/assets/img/advertising/section3/mascot.webp" type="image/webp">
        <img class="content__mascot" id="mascot3" src="@/assets/img/advertising/section3/mascot.png" alt="mascot">
      </picture>
    </div>

    <div class="content__col content__col--text">
      <h3 class="content__title js-scroll fade-in-bottom">Важливість здорової мікрофлори кишечника</h3>
      <p class="content__description content__description--first js-scroll fade-in-bottom ">Мікрофлора кишечника
        відповідає за низку
        фундаментальних процесів у людському організмі, включаючи допомогу у травленні, виробництво вітамінів, мінералів
        та інших цінних біологічних сполук. Вона також створює захисний щит проти навали патогенів та бере участь у
        формуванні та роботі імунної системи<sup>2</sup>.</p>
      <p class="content__description content__description--second js-scroll fade-in-bottom ">
        З огляду на це підтримка балансу мікрофлори кишечника є суттєвою для здоров'я імунної системи та організму
        загалом. Пізнання та мінімізація впливу факторів, що спричиняють її дисбаланс, є ключовими для профілактики
        порушень<sup>2</sup>.
      </p>
    </div>
  </div>

</template>

<style scoped lang="scss">
.content {
  padding: 108px 70px 62px 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  background: url("@/assets/img/advertising/section3/bg.jpg") no-repeat bottom center;
  background-size: cover;

  @media screen and (max-width: 1024px) {
    padding: 108px 20px 0 20px;
    flex-direction: column-reverse;
  }
  @media screen and (max-width: 600px) {
    padding: 60px 20px 0 20px;
  }

  &__col {
    position: relative;
    z-index: 2;

    &--image {
      position: relative;
      max-width: 740px;
      width: 100%;
      margin-right: 0;
    }

    &--text {
      //margin-top: 100px;
      max-width: 635px;
      width: 100%;
      @media screen and (max-width: 1024px) {
        max-width: 768px;
      }

    }
  }

  &__circle {
    max-width: 747px;
    width: 100%;
    height: auto;
    display: block;
  }

  &__mascot {
    width: 146px;
    height: auto;
    display: block;

    position: absolute;
    bottom: 9%;
    right: 9%;
    @media screen and (max-width: 600px) {
      width: 116px;
    }
  }

  &__title {
    color: #FFF;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    margin-bottom: 20px;
    @media screen and (max-width: 1024px) {
      font-size: 42px;
    }
    @media screen and (max-width: 600px) {
      font-size: 36px;
    }
  }

  &__description {
    color: #FFF;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    @media screen and (max-width: 1024px) {
      font-size: 24px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 20px;
    }

    &--first {
      margin-bottom: 10px;
    }
  }

  &__top-img {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: -1px;
    width: 100%;
    height: auto;
    display: block;
    transform: rotate(180deg);
  }
}
</style>