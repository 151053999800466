<script>
// @ is an alias to /src
import section1 from '@/components/advertising/section1.vue'
import section2 from '@/components/advertising/section2.vue'
import section3 from '@/components/advertising/section3.vue'
import section4 from '@/components/advertising/section4.vue'
import section5 from '@/components/advertising/section5.vue'
import section6 from '@/components/advertising/section6.vue'
import section7 from '@/components/advertising/section7.vue'
import sectionFooter from '@/components/advertising/section-footer.vue'
import selfMedication from '@/components/global/self-medication.vue'

export default {
  components: {
    section1,
    section2,
    section3,
    section4,
    section5,
    section6,
    section7,
    sectionFooter,
    selfMedication,
  },
  mounted() {
    const scrollElements = document.querySelectorAll(".js-scroll");

    const elementInView = (el, dividend = 1) => {
      const elementTop = el.getBoundingClientRect().top;

      return (
        elementTop <=
        (window.innerHeight || document.documentElement.clientHeight) / dividend
      );
    };

    const elementOutofView = (el) => {
      const elementTop = el.getBoundingClientRect().top;

      return (
        elementTop > (window.innerHeight || document.documentElement.clientHeight)
      );
    };

    const displayScrollElement = (element) => {
      element.classList.add("scrolled");
    };

    const hideScrollElement = (element) => {
      element.classList.remove("scrolled");
    };

    const handleScrollAnimation = () => {
      scrollElements.forEach((el) => {
        if (elementInView(el, 1.15)) {
          displayScrollElement(el);
        } else if (elementOutofView(el)) {
          hideScrollElement(el)
        }
      })
    }

    window.addEventListener("scroll", () => {
      handleScrollAnimation()
    });
  }
}
</script>

<template>
  <div class="advertising">
    <section class="section" id="section1">
      <section1/>
    </section>

    <section class="section" id="section2">
      <section2/>
    </section>

    <section class="section" id="section3">
      <section3/>
    </section>

    <section class="section" id="section4">
      <section4/>
    </section>

    <section class="section" id="section5">
      <section5/>
    </section>

    <section class="section" id="section6">
      <section6/>
      <div class="overlay"></div>
    </section>

    <section class="section" id="section7">
      <section7/>
    </section>

    <footer>
      <sectionFooter/>
    </footer>


    <selfMedication/>

  </div>
</template>

<style lang="scss">

.advertising {
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  padding-bottom: 15vh;
}
.section{
  position: relative;
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  pointer-events: none;
  z-index: 10;
}

.js-scroll {
  opacity: 0;
}

.scrolled.fade-in-bottom {
  animation: fade-in-bottom .6s ease-in-out both;

  &.delay1-img {
    animation-delay: 400ms;
  }

  &.delay1-block {
    animation-delay: 200ms;
  }

  &.delay2-block {
    animation-delay: 300ms;
  }

  &.delay3-block {
    animation-delay: 400ms;
  }

  &.delay4-block {
    animation-delay: 500ms;
  }
}

.scrolled.opacity-scale {
  transform-origin: center;
  animation: opacity-scale .6s ease-in-out both;
}

.scrolled.pack-bounce {
  transform-origin: center;
  animation: pack-bounce .8s ease-in-out both;

  &.delay1 {
    animation-delay: 600ms;
  }

}

.scrolled.mascot-second {
  transform-origin: center;
  animation: mascot-second .8s ease-in-out both;

  &.delay1 {
    animation-delay: 600ms;
  }
}


@keyframes fade-in-bottom {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes mascot-second {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateX(-45%);
  }
}

@keyframes opacity-scale {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pack-bounce {
  0% {
    transform: translateY(-60px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>


