<script>
export default {}
</script>

<template>
  <div class="content">
    <div class="content__col content__col--mr">
      <h4 class="content__title js-scroll fade-in-bottom">Дозування<sup>1</sup></h4>
      <div class="content__item content__item--mb js-scroll fade-in-bottom">
        <img class="content__image content__image--svg" src="@/assets/img/advertising/section5/icon1.svg" alt="family">
        <p class="content__description">
          <strong class="content__description-strong">Для дорослих та дітей від 28-го дня життя <span
              class="content__description-middle">—</span></strong><br>
          1 флакон на добу
        </p>
      </div>
      <div class="content__item js-scroll fade-in-bottom">
        <img class="content__image content__image--svg" src="@/assets/img/advertising/section5/icon2.svg"
             alt="calendar">
        <p class="content__description">
          <strong class="content__description-strong">З 1-го дня прийому антибіотиків**</strong>
        </p>
      </div>
    </div>

    <div class="content__col">
      <h4 class="content__title js-scroll fade-in-bottom">Склад продукту<sup>1</sup></h4>
      <div class="content__item js-scroll fade-in-bottom">

        <picture>
          <source srcset="@/assets/img/advertising/section5/icon3.webp" type="image/webp">
          <img class="content__image" src="@/assets/img/advertising/section5/icon3.png" alt="probiotic">
        </picture>

        <p class="content__description content__description--mb">
          <strong class="content__description-strong">Діюча речовина: </strong><br>
          спори полірезистентного штаму <i>Bacillus clausii</i>
        </p>
        <p class="content__description content__description--mb">
          <strong class="content__description-strong">1 флакон по 5 мл містить: </strong><br>
          спори полірезистентного штаму <i>Bacillus clausii</i>
          4&nbsp;×&nbsp;10<sup>9</sup>
        </p>
        <p class="content__description">
          <strong class="content__description-strong">Допоміжна речовина:</strong>
          вода очищена
        </p>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
.content {
  padding: 0 70px 90px 90px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    padding: 0 20px 60px 20px;
    flex-direction: column;
    align-items: center;
  }


  &__col {
    max-width: 587px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      max-width: 768px;
    }

    &--mr {
      margin-right: 100px;
      @media screen and (max-width: 1024px) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }

  &__title {
    color: #1A7DD2;
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;

    margin-bottom: 24px;
    @media screen and (max-width: 1024px) {
      font-size: 42px;
    }
    @media screen and (max-width: 600px) {
      font-size: 36px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: 24px 20px 24px 60px;

    border-radius: 30px;
    background: #D4F3FC;
    position: relative;

    @media screen and (max-width: 1024px) {
      margin-left: 40px;
    }
    @media screen and (max-width: 600px) {
      padding: 24px 20px 24px 20px;
      margin-left: 0;
      align-items: center;
    }

    &--mb {
      margin-bottom: 24px;
    }
  }


  &__image {
    position: absolute;
    left: -49px;
    top: 24px;
    width: 98px;
    height: auto;
    display: block;
    @media screen and (max-width: 600px) {
      position: relative;
      width: 86px;
      left: 0;
      top: 0;
      margin-bottom: 12px;
    }

    &--svg {
      width: 96px;
      top: 50%;
      transform: translateY(-50%);

      left: -48px;

      @media screen and (max-width: 600px) {
        width: 86px;
        left: 0;
        transform: translateY(0);
        top: 0;
      }
    }
  }

  &__description {
    color: #29343D;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    @media screen and (max-width: 1024px) {
      font-size: 24px;
    }
    @media screen and (max-width: 600px) {
      font-size: 20px;
      text-align: center;
    }

    &--mb {
      margin-bottom: 10px
    }

    &-strong {
      font-weight: 800;
    }

    &-middle {
      font-weight: 500;
    }

    i {
      font-style: italic;
    }
  }

}
</style>