<script>
export default {}
</script>

<template>
  <div class="content">

    <div class="content__card content__card--mr js-scroll fade-in-bottom">
      <img class="content__card-img" src="@/assets/img/advertising/section2/icon1.svg" alt="advantages">
      <h4 class="content__card-title">Дія пробіотика</h4>
      <p class="content__card-description">Для лікування та профілактики дисбактеріозу кишечника<sup>1</sup></p>
    </div>

    <div class="content__card content__card--mr js-scroll fade-in-bottom delay1-block">

      <picture>
        <source srcset="@/assets/img/advertising/section2/icon2.webp" type="image/webp">
        <img class="content__card-img content__card-img--png" src="@/assets/img/advertising/section2/icon2.png"
             alt="probiotic">
      </picture>

      <h4 class="content__card-title">Властивості<br>
        пробіотика</h4>
      <p class="content__card-description">Містить 4 антибіотикорезистентні штами (SIN, O/C, T, N/R)* <i>Bacillus
        clausii</i>,
        які відновлюють нормальну мікрофлору кишечника<sup>1</sup></p>
    </div>

    <div class="content__card content__card--mr js-scroll fade-in-bottom delay2-block">
      <img class="content__card-img" src="@/assets/img/advertising/section2/icon3.svg" alt="advantages">
      <h4 class="content__card-title">Для всієї родини</h4>
      <p class="content__card-description">Підходить для дорослих та дітей віком від 28 днів<sup>1</sup></p>
    </div>

    <div class="content__card js-scroll fade-in-bottom delay3-block">
      <img class="content__card-img" src="@/assets/img/advertising/section2/icon4.svg" alt="advantages">
      <h4 class="content__card-title">Зручний<br>
        у використанні</h4>
      <p class="content__card-description">Суспензія оральна у флаконі готова до вживання: потруси, відкрути та випий.
        Не містить смакових домішок, без запаху<sup>1</sup></p>
    </div>
  </div>

</template>

<style scoped lang="scss">
.content {
  padding: 90px 70px 90px 70px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    padding: 60px 20px 60px 20px;
    flex-direction: column;
    align-items: center;
  }


  &__card {
    max-width: 302px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      max-width: 320px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }


    @media screen and (min-width: 1024px) {
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    &--mr {
      margin-right: 30px;
      @media screen and (max-width: 1024px) {
        margin-right: 0;
        margin-bottom: 30px;
      }

    }

    &-img {
      max-width: 144px;
      width: 100%;
      height: auto;
      display: block;
      margin-bottom: 24px;
      transition: transform .4s ease-in-out;

      @media screen and (max-width: 1024px) {
        max-width: 180px;
      }
      @media screen and (max-width: 1024px) {
        max-width: 120px;
      }

      &--png {
        margin-bottom: 22px;
      }
    }

    &-title {
      color: #1A7DD2;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 30px;
      font-style: normal;
      line-height: 110%;
      align-self: stretch;
      margin-bottom: 8px;

      @media screen and (max-width: 1024px) {
        text-align: center;
        font-size: 36px;
        br {
          display: none;
        }
      }
      @media screen and (max-width: 600px) {
        font-size: 28px;
      }
    }

    &-description {
      color: #29343D;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      align-self: stretch;

      @media screen and (max-width: 1024px) {
        text-align: center;
        font-size: 24px;
      }
      @media screen and (max-width: 600px) {
        font-size: 20px;
      }

      i {
        font-style: italic;
      }
    }
  }
}
</style>