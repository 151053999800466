<template>
  <div class="wrapper" :class="[{ active: mobile }]">
    <router-view/>
  </div>
</template>
<script>
export default {
  data: () => ({
    mobile: false,
  }),
  mounted() {
    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
    let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);


    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
      this.mobile = true
    }
    if (navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)) {
      this.mobile = true
    }

    if (isAndroid || iOS === true) {
      this.mobile = true
    }
  }
}
</script>

<style lang="scss">
@import "./assets/style/fonts.css";
@import "./assets/style/vars";

.wrapper.active {
  @media screen and (max-width: 1024px) {
    cursor: none !important;
    outline: none !important;
  }
}
</style>
