<script>
export default {
  mounted() {

    let constrain = 100;
    let constrain2 = 50;
    let mouseOverContainer = document.getElementById("animation-wrapper");
    let ex1Layer = document.getElementById("mascot1");
    let ex2Layer = document.getElementById("mascot2");

    function transforms(x, y, el) {
      let box = el.getBoundingClientRect();
      let calcX = -(y - box.y - (box.height / 2)) / constrain;
      let calcY = (x - box.x - (box.width / 2)) / constrain;

      return "perspective(100px) "
          + "   rotateX(" + calcX + "deg) "
          + "   rotateY(" + calcY + "deg) ";
    }

    function transformsX(x, y, el) {
      let box = el.getBoundingClientRect();
      let calcY = (x - box.x - (box.width / 2)) / constrain2;

      return " translateX(" + calcY + "%) ";
    }

    function transformElement(el, xyEl) {
      el.style.transform = transforms.apply(null, xyEl);
    }

    function transformElementX(el, xyEl) {
      el.style.transform = transformsX.apply(null, xyEl);
    }

    const mql = window.matchMedia('(min-width: 1240px)');

    if (mql.matches) {
      mouseOverContainer.onmousemove = function (e) {
        let xy = [e.clientX, e.clientY];
        let position = xy.concat([ex1Layer]);
        let position2 = xy.concat([ex2Layer]);

        window.requestAnimationFrame(function () {
          transformElement(ex1Layer, position);
          transformElementX(ex2Layer, position2);
        });
      };
    }

  }
}
</script>

<template>
  <div class="content" id="animation-wrapper">

    <div class="content__col content__col--circle">
      <img class="content__circle js-scroll scrolled  opacity-scale" src="@/assets/img/advertising/section1/circle.png"
           alt="happy family">
    </div>

    <div class="content__col content__col--text">

      <h1 class="content__header js-scroll scrolled fade-in-bottom delay1">Дбайте<br>
        про животики</h1>

      <div class="content__scene">

        <picture>
          <source srcset="@/assets/img/advertising/section1/mascot2.webp" type="image/webp">
          <img id="mascot2" class="content__mascot content__mascot--second second   "
               src="@/assets/img/advertising/section1/mascot2.png" alt="mascot">
        </picture>

        <picture>
          <source srcset="@/assets/img/advertising/section1/pack.webp" type="image/webp">
          <img class="content__pack js-scroll scrolled pack-bounce delay1-img"
               src="@/assets/img/advertising/section1/pack.png" alt="Ентерожерміна® Форте">
        </picture>

        <picture>
          <source srcset="@/assets/img/advertising/section1/mascot1.webp" type="image/webp">
          <img id="mascot1" class="content__mascot content__mascot--first first"
               src="@/assets/img/advertising/section1/mascot1.png"
               alt="mascot">
        </picture>

      </div>

      <a
          href="https://tabletki.ua/uk/%D0%AD%D0%BD%D1%82%D0%B5%D1%80%D0%BE%D0%B6%D0%B5%D1%80%D0%BC%D0%B8%D0%BD%D0%B0-%D1%84%D0%BE%D1%80%D1%82%D0%B5/33798/pharmacy/kiev/?utm_source=tabletki&utm_medium=enterogermina&utm_campaign=button1_enterogermina"
          class="content__btn" target="_blank">

        ЗАБРОНЮВАТИ

        <img class="content__btn-arrow" src="@/assets/img/advertising/section7/arrow.svg" alt="arrow">

      </a>

    </div>

    <svg class="content__bottom-wave" preserveAspectRatio="none" data-bbox="-151 -1 1622.39 206.668"
         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 109" height="109" width="1440" data-type="ugc"
         role="presentation" aria-hidden="true" aria-label="">
      <g>
        <g clip-path="url(#8561dada-83c2-4f61-9557-9564c702a27d_comp-lwkhz6t4)">
          <path fill="#ffffff"
                d="M478.115 39.602C174.682-18.888-36.67 60.491-117.729 128.031-148.566 153.725-151 205.668-151 205.668h1622.39v-196S1454-4.81 1338 1.668c-116 6.477-254.37 56.401-396.517 86.327-162.06 34.116-298.225-16.56-463.368-48.393Z"></path>
          <path fill="url(#5b2196e8-de54-4577-bb56-5228b6328f57_comp-lwkhz6t4)"
                d="M1470.83 22.202V8.668s-17.39-14.477-133.39-8c-74.4 4.154-158.01 26.182-245.91 49.343-49.14 12.95-99.633 26.252-150.609 36.983-126.586 26.649-237.374 1.563-358.513-25.867-33.947-7.686-68.706-15.557-104.854-22.525C253.213-4.642 79.207 27.477-29.023 74.454c.25 26.79.867 43.334 1.961 45.714 103.533-45 267.217-104.84 481.202-67.98 28.623 4.93 57.055 10.245 85.362 15.535 154.239 28.83 304.739 56.961 461.938 18.445 12.05-2.954 23.92-5.886 35.62-8.777 158.66-39.2 286.7-70.834 433.38-45.223l.39-9.966Z"></path>
        </g>
        <defs fill="none">
          <linearGradient gradientUnits="userSpaceOnUse" y2="764.437" x2="732.841" y1="-157" x1="1471"
                          id="5b2196e8-de54-4577-bb56-5228b6328f57_comp-lwkhz6t4">
            <stop stop-color="#00A4EA"></stop>
            <stop stop-color="#0FC6BF" offset="1"></stop>
          </linearGradient>
          <clipPath id="8561dada-83c2-4f61-9557-9564c702a27d_comp-lwkhz6t4">
            <path fill="#ffffff" d="M1440 0v109H0V0h1440z"></path>
          </clipPath>
        </defs>
      </g>
    </svg>
  </div>
</template>

<style scoped lang="scss">

.content {
  display: flex;

  justify-content: center;
  padding-bottom: 40px;

  background: url("@/assets/img/advertising/section1/bg.jpg") no-repeat top center;
  background-size: cover;
  position: relative;
  min-height: calc(100vh - 15vh);


  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;
  }

  &__bottom-wave {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    width: 100%;
    height: auto;
    display: block;
  }

  &__col {
    &--text {
      //margin-top: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (max-width: 1024px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &--circle {
      display: flex;
      justify-content: flex-end;
      margin-right: 30px;
      @media screen and (max-width: 1024px) {
        margin-right: 0;
      }

    }
  }

  &__circle {
    max-width: 705px;
    width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
  }

  &__header {
    color: #FFF;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%;

    @media screen and (max-width: 1024px) {
      text-align: center;
      font-size: 80px;
    }
    @media screen and (max-width: 600px) {
      font-size: 47px;
    }
  }

  &__scene {
    position: relative;
    max-width: 654px;
    width: 100%;
    margin-top: 18px;

    @media screen and (max-width: 1024px) {
      max-width: 768px;
      margin-left: 20%;
    }
  }

  &__pack {
    max-width: 654px;
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    z-index: 2;

    padding-left: 35px;

    @media screen and (max-width: 1024px) {
      max-width: 768px;
      padding-left: 0;
    }
  }

  &__mascot {
    position: absolute;
    display: block;
    height: auto;
    transition: transform 500ms ease-out 0s;

    &--first {
      left: 0;
      bottom: 0;

      width: 206px;
      z-index: 3;
      transform-origin: center;


      @media screen and (max-width: 600px) {
        left: -5%;
        bottom: 0;
        width: 161px;
        z-index: 3;
      }
      @media screen and (max-width: 425px) {
        width: 120px;
      }
      @media screen and (max-width: 375px) {
        width: 100px;
      }
    }

    &--second {
      right: 5%;
      bottom: 20px;
      z-index: 1;

      width: 161px;


      @media screen and (max-width: 600px) {
        //right: -6%;
        width: 141px;
      }
      @media screen and (max-width: 425px) {
        width: 100px;
      }
      @media screen and (max-width: 375px) {
        width: 80px;
      }
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 211px;
    width: 100%;

    margin-top: 40px;

    border-radius: 50px;
    border: 3px solid rgba(255, 255, 255, 0.30);
    background: linear-gradient(184deg, rgba(225, 110, 76, 0.00) 40.59%, rgba(246, 97, 0, 0.60) 95.46%), linear-gradient(354deg, #FF7C56 62.62%, #FFAA2F 99.95%);
    box-shadow: 2px 5px 20px 0 rgba(0, 83, 142, 0.25);

    color: #FFF;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    text-decoration: none;

    padding: 12px 24px;

    cursor: pointer;
    position: relative;
    z-index: 2;


    @media screen and (max-width: 1024px) {
      max-width: 300px;
      font-size: 28px;
    }
    @media screen and (max-width: 600px) {
      font-size: 24px;
    }
    @media screen and (max-width: 425px) {
      font-size: 18px;
      max-width: 220px;
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        img {
          transform: translateX(4px);
        }
      }
    }

    &:active {
      img {
        transform: translateX(4px);
      }
    }

    &-arrow {
      width: 24px;
      height: auto;
      display: block;
      margin-left: 8px;
      transition: transform .2s ease-in;
      @media screen and (max-width: 1024px) {
        width: 28px;
      }
      @media screen and (max-width: 425px) {
        font-size: 20px;
      }
    }
  }
}
</style>