<script>
export default {}
</script>

<template>
  <div class="content">
    <h3 class="content__title js-scroll fade-in-bottom">Які фактори можуть впливати на порушення балансу мікрофлори
      кишечника?</h3>

    <div class="content__row">
      <div class="content__item content__item--mr js-scroll fade-in-bottom delay1-block">

        <picture>
          <source srcset="@/assets/img/advertising/section4/icon1.webp" type="image/webp">
          <img class="content__image" src="@/assets/img/advertising/section4/icon1.png" alt="influencing factors">
        </picture>

        <h4 class="content__item-title">Антибіотики<br>
          та інші ліки</h4>
        <p class="content__description">Ліки, включаючи антибіотики, зазвичай уповільнюють ріст корисних кишкових
          бактерій,
          що може спричинити порушення кишкової мікрофлори<sup>3</sup></p>
      </div>

      <div class="content__item content__item--mr js-scroll fade-in-bottom delay2-block">
        <picture>
          <source srcset="@/assets/img/advertising/section4/icon2.webp" type="image/webp">
          <img class="content__image" src="@/assets/img/advertising/section4/icon2.png" alt="influencing factors">
        </picture>
        <h4 class="content__item-title">Незбалансоване харчування</h4>
        <p class="content__description">Незбалансоване харчування — одна з основних причин періодичного порушення
          кишкової мікрофлори
          як у дорослих, так і дітей<sup>4,5</sup></p>
      </div>

      <div class="content__item content__item--mr js-scroll fade-in-bottom delay3-block">
        <picture>
          <source srcset="@/assets/img/advertising/section4/icon3.webp" type="image/webp">
          <img class="content__image" src="@/assets/img/advertising/section4/icon3.png" alt="influencing factors">
        </picture>

        <h4 class="content__item-title">Стрес</h4>
        <p class="content__description">Стрес може спровокувати значне порушення кишкової мікрофлори, включаючи діарею,
          запор, спазми та затримку газів<sup>6</sup></p>
      </div>

      <div class="content__item js-scroll fade-in-bottom delay4-block">
        <picture>
          <source srcset="@/assets/img/advertising/section4/icon4.webp" type="image/webp">
          <img class="content__image" src="@/assets/img/advertising/section4/icon4.png" alt="influencing factors">
        </picture>
        <h4 class="content__item-title">Подорожі</h4>
        <p class="content__description">Подорожі часто можуть призводити до контакту з багатьма патогенними бактеріями
          та, як результат, спричиняти діарею мандрівників<sup>7</sup></p>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
.content {
  padding: 90px 70px 90px 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    padding: 60px 20px 60px 20px;

  }

  &__title {
    color: #1A7DD2;
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    margin-bottom: 50px;

    max-width: 1300px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      font-size: 42px;
    }
    @media screen and (max-width: 600px) {
      font-size: 36px;
      margin-bottom: 24px;
    }
  }

  &__row {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    max-width: 302px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      max-width: 435px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media screen and (max-width: 600px) {
      max-width: 320px;
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    &--mr {
      margin-right: 30px;
      @media screen and (max-width: 1024px) {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }

    &-title {
      color: #1A7DD2;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      align-self: stretch;
      margin-bottom: 8px;
      min-height: 66px;
      @media screen and (max-width: 1140px) {
        font-size: 28px;
      }
      @media screen and (max-width: 1024px) {
        text-align: center;
        font-size: 36px;
        min-height: 1px;

        br {
          display: none;
        }
      }
      @media screen and (max-width: 600px) {
        font-size: 28px;
      }

    }
  }

  &__image {
    max-width: 240px;
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 24px;
    transition: transform .4s ease-in-out;
    @media screen and (max-width: 600px) {
      max-width: 180px;
    }
  }

  &__description {
    color: #29343D;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    align-self: stretch;
    @media screen and (max-width: 1024px) {
      font-size: 24px;
      text-align: center;
    }
    @media screen and (max-width: 600px) {
      font-size: 20px;
    }
  }

}
</style>