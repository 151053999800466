<script>
export default {}
</script>

<template>
  <div class="content">
    <h3 class="content__title js-scroll fade-in-bottom">Дізнайтеся більше про життя Ентеро та Жерміни</h3>

    <div class="content__youtube-wrapper js-scroll fade-in-bottom">

      <iframe src="https://www.youtube.com/embed/gShcbAHdnVI?si=FWBDUvbgaz3r6s72"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <div class="overlay"></div>
    </div>
  </div>

</template>

<style scoped lang="scss">
.content {
  max-width: 1298px;
  width: 100%;
  margin: 0 auto;

  &__youtube-wrapper {
    position: relative;
    width: auto;
    padding-bottom: 56.25%;



    iframe {
      z-index: 2;
      overscroll-behavior: contain;
      -webkit-overflow-scrolling: auto;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  &__title {
    margin-bottom: 50px;
    padding: 0 70px 0 70px;

    text-align: center;

    color: #1A7DD2;
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    @media screen and (max-width: 1024px) {
      font-size: 42px;
      padding: 0 20px;
    }
    @media screen and (max-width: 600px) {
      font-size: 36px;
      margin-bottom: 24px;
    }

  }

}
</style>