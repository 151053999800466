<script setup>

</script>

<template>
  <div class="self-medication">
    <svg class="self-medication__desk" preserveAspectRatio="none" data-bbox="3 3 1197.27 74"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="3 3 1197.27 74" height="78" width="1204" data-type="color" role="presentation"
         aria-hidden="true" aria-label="">

      <g>
        <path fill="#2B2F30"
              d="M11.92 3.002a6.72 6.72 0 0 1 4.15 1.32 9.39 9.39 0 0 1 2.78 3.48 18.771 18.771 0 0 1 1.55 5.17 38.7 38.7 0 0 1 .49 6.27v4.94h-1.35v-5a37.001 37.001 0 0 0-.4-5.54 16.999 16.999 0 0 0-1.28-4.53 8.09 8.09 0 0 0-2.35-3.07 5.57 5.57 0 0 0-3.54-1.14 5.66 5.66 0 0 0-3.6 1.14 8.09 8.09 0 0 0-2.34 3 16.9 16.9 0 0 0-1.28 4.53 36.82 36.82 0 0 0-.4 5.54v32.75a35.871 35.871 0 0 0 .4 5.46 16.89 16.89 0 0 0 1.28 4.48 8.09 8.09 0 0 0 2.35 3.07 5.66 5.66 0 0 0 3.6 1.14 5.57 5.57 0 0 0 3.54-1.14 8.09 8.09 0 0 0 2.35-3.07 16.997 16.997 0 0 0 1.28-4.48c.274-1.817.407-3.653.4-5.49v-7h1.34v7a38.7 38.7 0 0 1-.49 6.27 18.779 18.779 0 0 1-1.55 5.17 9.38 9.38 0 0 1-2.78 3.48 6.72 6.72 0 0 1-4.15 1.28 6.58 6.58 0 0 1-4.12-1.28 9.49 9.49 0 0 1-2.75-3.48 18.75 18.75 0 0 1-1.56-5.17 38.59 38.59 0 0 1-.49-6.27v-32.63a38.59 38.59 0 0 1 .49-6.27c.271-1.79.8-3.532 1.57-5.17a9.5 9.5 0 0 1 2.74-3.44 6.58 6.58 0 0 1 4.12-1.32Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="m29.18 52.412-2.5 15.1h-1.22l10.31-64.19h1.89l10.55 64.19h-1.34l-2.5-15.1H29.18Zm.24-1.83h14.7l-7.43-44.84-7.27 44.84Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M66.21 67.322h-1.53L54.13 6.482v61h-1.22v-64h2.07l10.5 60.73 10.49-60.75h2.07v64H76.7v-61l-10.49 60.86Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M82.86 19.202a38.42 38.42 0 0 1 .49-6.31 17.996 17.996 0 0 1 1.59-5.12 9.639 9.639 0 0 1 2.8-3.45 6.72 6.72 0 0 1 4.15-1.32 6.85 6.85 0 0 1 4.18 1.32 9.512 9.512 0 0 1 2.84 3.48 18.713 18.713 0 0 1 1.62 5.12c.357 2.084.531 4.195.52 6.31v32.54a36.256 36.256 0 0 1-.52 6.31 18.717 18.717 0 0 1-1.62 5.12 9.5 9.5 0 0 1-2.84 3.48 6.85 6.85 0 0 1-4.18 1.28 6.72 6.72 0 0 1-4.15-1.28 9.628 9.628 0 0 1-2.81-3.48 17.995 17.995 0 0 1-1.59-5.12 38.351 38.351 0 0 1-.49-6.31l.01-32.57Zm1.34 32.67a36.777 36.777 0 0 0 .4 5.54 16.207 16.207 0 0 0 1.31 4.53 8.369 8.369 0 0 0 2.38 3.05 5.66 5.66 0 0 0 3.6 1.14 5.79 5.79 0 0 0 3.63-1.14 8.22 8.22 0 0 0 2.41-3.06 16.91 16.91 0 0 0 1.36-4.53c.293-1.832.437-3.685.43-5.54v-32.76a34.34 34.34 0 0 0-.43-5.54 16.894 16.894 0 0 0-1.34-4.53 8.231 8.231 0 0 0-2.41-3.07 5.8 5.8 0 0 0-3.65-1.13 5.67 5.67 0 0 0-3.6 1.14 8.379 8.379 0 0 0-2.38 3.07 16.197 16.197 0 0 0-1.31 4.53 36.804 36.804 0 0 0-.4 5.54v32.76Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="m113.91 5.382-1.22 46.66a209.79 209.79 0 0 1-.31 5.58 23.293 23.293 0 0 1-.82 4.94 8.518 8.518 0 0 1-2 3.57 5.002 5.002 0 0 1-3.57 1.46h-.43v-1.91a4.118 4.118 0 0 0 3.2-1.24 7.753 7.753 0 0 0 1.73-3.12c.387-1.42.612-2.88.67-4.35.08-1.613.14-3.243.18-4.89l1.28-48.59h14.82v64h-1.4V5.382h-12.13Z"
              data-color="1"></path>
        <path fill="#2B2F30" d="M133.67 3.462v64h-1.38v-64h1.38Z" data-color="1"></path>
        <path fill="#2B2F30"
              d="m143.13 35.672-3.23 7.32v24.52h-1.34v-64h1.34v36.09l15.85-36.14h1.46l-13.23 30.28 14.31 33.77h-1.46l-13.7-31.84Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M172.29 57.262a49.752 49.752 0 0 1-1.07 4.3 12.202 12.202 0 0 1-1.46 3.25 6.086 6.086 0 0 1-2.13 2 6.471 6.471 0 0 1-3.14.69v-1.82a4.901 4.901 0 0 0 2.53-.59 5.388 5.388 0 0 0 1.74-1.69c.53-.821.941-1.713 1.22-2.65.326-1.04.66-2.2 1-3.48l2.44-10.43-10.65-43.38h1.4l9.88 40.72 4.09-18 5.15-22.72h1.46l-12.46 53.8Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M197.97 3.462c3.087 0 5.23 1.143 6.43 3.43 1.2 2.287 1.8 5.567 1.8 9.84v4.12c.01 1.603-.07 3.206-.24 4.8a13.703 13.703 0 0 1-.92 3.75 7.538 7.538 0 0 1-1.8 2.65 5.928 5.928 0 0 1-3 1.42 6.997 6.997 0 0 1 5.28 4.44 24.268 24.268 0 0 1 1.5 9.2v5.67c0 4.513-.7 8.097-2.1 10.75a7.234 7.234 0 0 1-6.92 4h-8.6v-64l8.57-.07Zm-1.68 29.09a14.071 14.071 0 0 0 3.81-.46 5.29 5.29 0 0 0 2.71-1.77 8.138 8.138 0 0 0 1.56-3.52c.362-1.885.526-3.802.49-5.72v-4.26c0-3.78-.477-6.633-1.43-8.56-.953-1.927-2.773-2.887-5.46-2.88h-7.2v27.17h5.52Zm1.71 33a6.142 6.142 0 0 0 5.95-3.38c1.16-2.253 1.74-5.393 1.74-9.42v-5.74c0-4.667-.733-7.93-2.2-9.79-1.467-1.86-3.663-2.807-6.59-2.84h-6.16v31.2l7.26-.03Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="m215.35 52.412-2.5 15.1h-1.22l10.31-64.19h1.89l10.55 64.14h-1.34l-2.5-15.1-15.19.05Zm.24-1.83h14.7l-7.44-44.84-7.26 44.84Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M240.42 36.402v31.11h-1.34v-64h1.34v31.06h15.43V3.462h1.34v64h-1.34v-31.06h-15.43Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M263.42 36.402v31.11h-1.34v-64h1.34v31.06h15.43V3.462h1.34v64h-1.34v-31.06h-15.43Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M296.79 37.952a12.3 12.3 0 0 0-3.78.55 6.92 6.92 0 0 0-3 1.88 8.785 8.785 0 0 0-1.92 3.61 21.243 21.243 0 0 0-.67 5.86v10.06c0 1.407-.06 2.763-.18 4.07a11.525 11.525 0 0 1-.85 3.52h-1.46a11.25 11.25 0 0 0 .95-3.66c.136-1.306.206-2.617.21-3.93v-10.06a20.225 20.225 0 0 1 1.59-8.65 7.259 7.259 0 0 1 5.18-4.16 7.925 7.925 0 0 1-5.15-4.25c-1.08-2.167-1.62-5.234-1.62-9.2v-5.77c0-4.393.667-7.887 2-10.48a6.995 6.995 0 0 1 6.77-3.89h8.48v64H302v-29.5h-5.21Zm-1.83-32.57a5.997 5.997 0 0 0-5.83 3.38 21.104 21.104 0 0 0-1.68 9.24v5.86a23.61 23.61 0 0 0 .64 6.08 9.367 9.367 0 0 0 1.83 3.71 5.998 5.998 0 0 0 2.93 1.88 14.1 14.1 0 0 0 3.93.5h5.25V5.382h-7.07Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M331.01 67.322h-1.53l-10.55-60.84v61h-1.22v-64h2.07l10.51 60.73 10.48-60.75h2.07v64h-1.34v-61l-10.49 60.86Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M347.66 19.202a38.32 38.32 0 0 1 .49-6.31 18.005 18.005 0 0 1 1.59-5.12 9.643 9.643 0 0 1 2.8-3.45 6.72 6.72 0 0 1 4.15-1.28 6.85 6.85 0 0 1 4.18 1.28 9.508 9.508 0 0 1 2.84 3.48 18.708 18.708 0 0 1 1.58 5.08 36.26 36.26 0 0 1 .52 6.31v32.58a36.256 36.256 0 0 1-.52 6.31 18.724 18.724 0 0 1-1.62 5.12 9.504 9.504 0 0 1-2.84 3.48 6.849 6.849 0 0 1-4.18 1.28 6.72 6.72 0 0 1-4.15-1.28 9.633 9.633 0 0 1-2.81-3.48 18.005 18.005 0 0 1-1.59-5.12 38.318 38.318 0 0 1-.49-6.31l.05-32.57Zm1.34 32.67a36.816 36.816 0 0 0 .4 5.54 16.23 16.23 0 0 0 1.31 4.53 8.37 8.37 0 0 0 2.38 3.06 5.66 5.66 0 0 0 3.6 1.14 5.789 5.789 0 0 0 3.6-1.15 8.213 8.213 0 0 0 2.41-3.06 16.87 16.87 0 0 0 1.34-4.53 34.26 34.26 0 0 0 .43-5.54v-32.76a34.26 34.26 0 0 0-.43-5.54 16.868 16.868 0 0 0-1.34-4.53 8.227 8.227 0 0 0-2.41-3.06 5.799 5.799 0 0 0-3.63-1.14 5.671 5.671 0 0 0-3.6 1.14 8.379 8.379 0 0 0-2.38 3.07 16.196 16.196 0 0 0-1.31 4.53 36.817 36.817 0 0 0-.4 5.54l.03 32.76Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="m391.39 34.112-3.35 8.14v25.26h-1.34v-25.26l-3.23-8.14-11.65 33.4h-1.4l12.19-35.19-11.53-28.86h1.4l11.6 28.86 2.62 6.77V3.462h1.34v35.59l2.68-7 11.57-28.59h1.46l-11.46 28.86 12.1 35.19h-1.4l-11.6-33.4Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M422.99 34.112v1.83h-12.56v29.65h14.94v1.92h-16.29v-64h16.29v1.87h-14.94v28.73h12.56Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M447.82 31.322c3.293 0 5.763 1 7.41 3 1.646 2 2.47 5.57 2.47 10.71v7.41c0 4.76-.71 8.45-2.13 11.07-1.42 2.62-3.78 3.93-7.08 3.93h-8.91v-64h16.65v1.94h-15.3v25.94h6.89Zm.67 34.22c2.886 0 4.92-1.126 6.1-3.38 1.18-2.253 1.77-5.456 1.77-9.61v-7.55c0-4.573-.71-7.67-2.13-9.29-1.42-1.62-3.557-2.416-6.41-2.39h-6.89v32.3l7.56-.08Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M471.79 57.262a49.752 49.752 0 0 1-1.07 4.3 12.231 12.231 0 0 1-1.43 3.25 6.086 6.086 0 0 1-2.13 2 6.471 6.471 0 0 1-3.14.69v-1.82a4.901 4.901 0 0 0 2.53-.59 5.39 5.39 0 0 0 1.74-1.7c.53-.821.941-1.713 1.22-2.65.326-1.04.66-2.2 1-3.48l2.44-10.43-10.66-43.37h1.4l9.88 40.72 4.09-18 5.18-22.69h1.45l-12.5 53.77Z"
              data-color="1"></path>
        <path fill="#2B2F30" d="M498.02 67.512V5.382h-9.33v-1.92h20v1.92h-9.33v62.13h-1.34Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="m522.48 37.132-7.5 30.38h-1.69v-64h1.28v60.15l15.11-60.2h1.83v64h-1.28V6.572l-7.75 30.56Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M552.351 3.473v62.555h12.457V3.473h1.352v62.555h12.407V3.473h1.352v64.496H551V3.473h1.351Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="m589.378 35.905-3.256 7.37V67.97h-1.351V3.473h1.351v36.4l15.971-36.4h1.474l-13.317 30.49 14.435 33.993h-1.474l-13.833-32.051Z"
              data-color="1"></path>
        <path fill="#2B2F30" d="M610.754 3.473v64.496h-1.351V3.473h1.351Z" data-color="1"></path>
        <path fill="#2B2F30"
              d="M639.623 77v-9.03h-22.788V77h-1.352V66.04h3.072a11.476 11.476 0 0 0 1.781-4.914c.246-1.9.409-3.947.491-6.142l1.782-51.499h14.557V66.04h3.809V77h-1.352Zm-17.444-21.93a83.001 83.001 0 0 1-.43 5.996 11.955 11.955 0 0 1-1.659 4.914h15.725V5.403h-11.916l-1.72 49.668Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="m653.874 5.4-1.228 46.99a242.64 242.64 0 0 1-.307 5.627 23.329 23.329 0 0 1-.823 4.914 8.607 8.607 0 0 1-1.966 3.587 5.035 5.035 0 0 1-3.587 1.475h-.442v-1.941a4.154 4.154 0 0 0 3.23-1.228 7.805 7.805 0 0 0 1.696-3.183 19.54 19.54 0 0 0 .675-4.373c.082-1.638.144-3.277.185-4.914l1.228-48.931h14.976v64.496h-1.413V5.4h-12.224Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="m681.638 37.38-7.493 30.589h-1.72V3.473h1.228V64.05l15.234-60.577h1.842v64.496h-1.29V6.544l-7.801 30.835Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M704.242 3.473c3.112 0 5.274 1.15 6.487 3.452 1.212 2.301 1.814 5.602 1.806 9.901v4.153a43.476 43.476 0 0 1-.246 4.84 13.863 13.863 0 0 1-.921 3.784 7.63 7.63 0 0 1-1.806 2.666 5.98 5.98 0 0 1-2.973 1.425 7.101 7.101 0 0 1 3.238 1.541 7.107 7.107 0 0 1 2.069 2.93 24.456 24.456 0 0 1 1.511 9.263v5.713a23.355 23.355 0 0 1-2.125 10.823 7.284 7.284 0 0 1-6.966 4.005h-8.599V3.473h8.525Zm-1.658 29.3c1.296.025 2.59-.129 3.845-.455a5.31 5.31 0 0 0 2.703-1.745 8.192 8.192 0 0 0 1.572-3.55 27.84 27.84 0 0 0 .492-5.762v-4.336a20.063 20.063 0 0 0-1.45-8.6c-.958-1.933-2.789-2.899-5.491-2.899h-7.249v27.359l5.578-.013Zm1.72 33.267a6.141 6.141 0 0 0 5.995-3.403 21.084 21.084 0 0 0 1.744-9.496v-5.799c0-4.668-.737-7.944-2.211-9.828a7.879 7.879 0 0 0-6.634-2.862h-6.204v31.425l7.31-.037Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="m727.399 37.379-7.555 30.589h-1.658V3.472h1.228v60.577l15.172-60.626h1.843v64.496H735.2V6.494l-7.801 30.885Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M754.795 67.735h-1.535L742.633 6.47v61.425h-1.228V3.473h2.088l10.565 61.179 10.565-61.18h2.089V67.97h-1.352V6.544l-10.565 61.191Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M805.102 77v-9.03h-22.789V77h-1.29V66.04h3.072a11.489 11.489 0 0 0 1.781-4.914c.246-1.9.409-3.947.491-6.142l1.782-51.499h14.496V66.04h3.808V77h-1.351Zm-17.445-21.93a83.576 83.576 0 0 1-.43 5.996 11.954 11.954 0 0 1-1.658 4.914h15.725V5.403h-11.917l-1.72 49.668Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="m819.353 5.4-1.229 46.99a242.64 242.64 0 0 1-.307 5.627 23.265 23.265 0 0 1-.823 4.914 8.596 8.596 0 0 1-1.966 3.587 5.028 5.028 0 0 1-3.587 1.474h-.43v-1.94a4.161 4.161 0 0 0 1.75-.262 4.163 4.163 0 0 0 1.481-.967 7.803 7.803 0 0 0 1.695-3.182 19.55 19.55 0 0 0 .676-4.373c.082-1.638.144-3.277.184-4.914l1.229-48.931h14.963v64.496h-1.413V5.4h-12.223Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M849.697 38.252a12.434 12.434 0 0 0-3.809.553 6.966 6.966 0 0 0-2.973 1.892 8.84 8.84 0 0 0-1.941 3.685 21.393 21.393 0 0 0-.675 5.897v10.086c0 1.416-.062 2.785-.185 4.103a11.573 11.573 0 0 1-.86 3.55h-1.474c.521-1.165.841-2.412.946-3.685a39.89 39.89 0 0 0 .209-3.956V50.242a20.36 20.36 0 0 1 1.597-8.71 7.368 7.368 0 0 1 5.221-4.19 7.979 7.979 0 0 1-5.196-4.287 21.217 21.217 0 0 1-1.634-9.262v-5.86A23.128 23.128 0 0 1 840.95 7.38a7.063 7.063 0 0 1 6.818-3.919h8.599v64.496h-1.351V38.252h-5.319Zm-1.843-32.8a6.009 6.009 0 0 0-5.86 3.316 21.246 21.246 0 0 0-1.683 9.312v5.897a23.867 23.867 0 0 0 .639 6.142 9.444 9.444 0 0 0 1.843 3.686 6.03 6.03 0 0 0 2.948 1.892c1.289.352 2.62.522 3.956.503h5.282V5.402l-7.125.05Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M879.303 3.473c3.112 0 5.274 1.15 6.487 3.452 1.212 2.301 1.814 5.602 1.805 9.901v4.153a43.236 43.236 0 0 1-.245 4.84 13.865 13.865 0 0 1-.922 3.784 7.617 7.617 0 0 1-1.806 2.666 5.973 5.973 0 0 1-2.972 1.425 7.101 7.101 0 0 1 3.238 1.541 7.107 7.107 0 0 1 2.069 2.93 24.456 24.456 0 0 1 1.511 9.263v5.713a23.343 23.343 0 0 1-2.126 10.823 7.281 7.281 0 0 1-6.965 4.005h-8.6V3.473h8.526Zm-1.658 29.3a14.148 14.148 0 0 0 3.845-.455 5.31 5.31 0 0 0 2.703-1.745 8.192 8.192 0 0 0 1.572-3.55 27.77 27.77 0 0 0 .491-5.762v-4.336a20.047 20.047 0 0 0-1.449-8.6c-.958-1.933-2.789-2.899-5.492-2.899h-7.248v27.359l5.578-.013Zm1.72 33.267a6.144 6.144 0 0 0 5.995-3.403 21.084 21.084 0 0 0 1.744-9.496v-5.799c0-4.668-.737-7.944-2.211-9.828a7.882 7.882 0 0 0-6.634-2.862h-6.204v31.425l7.31-.037Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="m896.871 52.76-2.518 15.197h-1.229l10.381-64.595h1.904l10.626 64.595h-1.351l-2.518-15.197h-15.295Zm.246-1.843h14.803L904.426 5.77l-7.309 45.147Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M922.116 3.473v62.555h12.408V3.473h1.29v62.555h12.408V3.473h1.351v64.496h-28.869V3.473h1.412Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M954.487 19.321a38.74 38.74 0 0 1 .491-6.363c.272-1.79.81-3.53 1.598-5.16a9.699 9.699 0 0 1 2.825-3.501 6.768 6.768 0 0 1 4.177-1.229 6.905 6.905 0 0 1 4.214 1.229 9.574 9.574 0 0 1 2.85 3.501 18.86 18.86 0 0 1 1.634 5.16 36.67 36.67 0 0 1 .516 6.363v32.801a36.667 36.667 0 0 1-.516 6.364 18.862 18.862 0 0 1-1.634 5.16 9.554 9.554 0 0 1-2.85 3.5 6.899 6.899 0 0 1-4.214 1.23 6.766 6.766 0 0 1-4.177-1.23 9.67 9.67 0 0 1-2.825-3.5 18.106 18.106 0 0 1-1.598-5.16 38.749 38.749 0 0 1-.491-6.364v-32.8Zm1.351 32.887a37.19 37.19 0 0 0 .406 5.578 16.253 16.253 0 0 0 1.327 4.557 8.417 8.417 0 0 0 2.457 3.084 5.695 5.695 0 0 0 3.624 1.154 5.833 5.833 0 0 0 3.685-1.154 8.297 8.297 0 0 0 2.457-3.084 17.036 17.036 0 0 0 1.352-4.558c.293-1.843.437-3.71.429-5.577V19.223a34.607 34.607 0 0 0-.429-5.577 17.037 17.037 0 0 0-1.352-4.558 8.315 8.315 0 0 0-2.457-3.084 5.832 5.832 0 0 0-3.685-1.154 5.699 5.699 0 0 0-3.624 1.154 8.414 8.414 0 0 0-2.457 3.084 16.237 16.237 0 0 0-1.339 4.595 37.052 37.052 0 0 0-.406 5.577l.012 32.948Z"
              data-color="1"></path>
        <path fill="#2B2F30" d="M978.996 5.401v62.555h-1.413V3.473h15.97v1.94l-14.557-.012Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M998.406 19.321a38.74 38.74 0 0 1 .491-6.363c.272-1.79.81-3.53 1.593-5.16a9.807 9.807 0 0 1 2.83-3.501 6.779 6.779 0 0 1 4.18-1.229c1.5-.06 2.98.372 4.21 1.229a9.554 9.554 0 0 1 2.85 3.501c.79 1.632 1.34 3.37 1.63 5.16.36 2.102.53 4.231.52 6.363v32.801c.01 2.133-.16 4.262-.52 6.364-.29 1.79-.84 3.527-1.63 5.16a9.544 9.544 0 0 1-2.85 3.5 6.881 6.881 0 0 1-4.21 1.23 6.777 6.777 0 0 1-4.18-1.23 9.798 9.798 0 0 1-2.83-3.5 18.255 18.255 0 0 1-1.593-5.16 38.749 38.749 0 0 1-.491-6.364v-32.8Zm1.351 32.887c-.005 1.866.13 3.731.403 5.578a16.46 16.46 0 0 0 1.33 4.557 8.313 8.313 0 0 0 2.46 3.084 5.693 5.693 0 0 0 3.62 1.154 5.871 5.871 0 0 0 3.69-1.154 8.339 8.339 0 0 0 2.45-3.084 16.888 16.888 0 0 0 1.35-4.558c.3-1.843.44-3.71.43-5.577V19.223a34.34 34.34 0 0 0-.56-5.54 17.25 17.25 0 0 0-1.35-4.558 8.363 8.363 0 0 0-2.46-3.084 5.824 5.824 0 0 0-3.68-1.155 5.706 5.706 0 0 0-3.63 1.155 8.516 8.516 0 0 0-2.46 3.084 16.351 16.351 0 0 0-1.2 4.557 37.182 37.182 0 0 0-.405 5.578l.012 32.948Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M1040.05 3.006c1.49-.062 2.96.37 4.18 1.228a9.228 9.228 0 0 1 2.76 3.502 19.415 19.415 0 0 1 1.54 5.16c.34 2.103.5 4.232.49 6.363v1.658a19.78 19.78 0 0 1-1.66 8.342 9.183 9.183 0 0 1-4.61 4.914 8.05 8.05 0 0 1 2.86 1.822 8.067 8.067 0 0 1 1.85 2.834 20.266 20.266 0 0 1 1.56 8.243v4.914c.01 2.131-.15 4.26-.49 6.364a19.408 19.408 0 0 1-1.54 5.16 9.214 9.214 0 0 1-2.76 3.5 7.386 7.386 0 0 1-4.18 1.298c-1.49 0-2.95-.452-4.17-1.298a9.38 9.38 0 0 1-2.81-3.5 19.362 19.362 0 0 1-1.57-5.16 38.76 38.76 0 0 1-.49-6.364v-4.693h1.35v4.791c0 1.866.13 3.731.41 5.578a16.78 16.78 0 0 0 1.23 4.557 8.235 8.235 0 0 0 2.33 3.084 5.553 5.553 0 0 0 3.59 1.155 5.684 5.684 0 0 0 3.68-1.155 8.13 8.13 0 0 0 2.36-3.133 17.532 17.532 0 0 0 1.23-4.607c.27-1.846.41-3.711.4-5.577v-4.914c.12-2.97-.57-5.915-1.99-8.526a6.053 6.053 0 0 0-2.24-2.444 6.149 6.149 0 0 0-3.19-.922h-2.76v-1.843h2.83a4.774 4.774 0 0 0 2.94-1.02 9.083 9.083 0 0 0 2.34-2.665 14.22 14.22 0 0 0 1.53-3.87c.38-1.507.56-3.054.56-4.607V19.32c0-1.895-.13-3.788-.41-5.663a16.785 16.785 0 0 0-1.23-4.558 8.029 8.029 0 0 0-2.36-3.083 6.26 6.26 0 0 0-7.24 0 8.025 8.025 0 0 0-2.36 3.083 16.778 16.778 0 0 0-1.23 4.558c-.28 1.846-.41 3.71-.41 5.577v2.764h-1.22v-2.776c-.01-2.098.15-4.194.49-6.265.27-1.783.78-3.52 1.53-5.16a9.149 9.149 0 0 1 2.77-3.501 6.756 6.756 0 0 1 4.11-1.29Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M1077.83 77v-9.03h-22.85V77h-1.35V66.04h3.07c.96-1.479 1.57-3.16 1.78-4.914.24-1.9.41-3.947.49-6.142l1.78-51.499h14.62V66.04h3.81V77h-1.35Zm-17.45-21.93a79.19 79.19 0 0 1-.43 5.996c-.18 1.74-.75 3.418-1.66 4.914h15.73V5.403h-11.92l-1.72 49.668Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M1083.9 19.321c-.01-2.13.15-4.26.49-6.363.27-1.79.81-3.53 1.6-5.16a9.675 9.675 0 0 1 2.82-3.501 6.779 6.779 0 0 1 4.18-1.229c1.5-.06 2.98.372 4.21 1.229a9.554 9.554 0 0 1 2.85 3.501 19.08 19.08 0 0 1 1.64 5.16c.35 2.102.52 4.231.51 6.363v32.801a37.67 37.67 0 0 1-.51 6.364c-.3 1.79-.85 3.527-1.64 5.16a9.544 9.544 0 0 1-2.85 3.5 6.881 6.881 0 0 1-4.21 1.23 6.777 6.777 0 0 1-4.18-1.23 9.667 9.667 0 0 1-2.82-3.5c-.79-1.63-1.33-3.37-1.6-5.16a37.681 37.681 0 0 1-.49-6.364v-32.8Zm1.35 32.887c-.01 1.866.13 3.731.4 5.578a16.46 16.46 0 0 0 1.33 4.557 8.414 8.414 0 0 0 2.46 3.084 5.693 5.693 0 0 0 3.62 1.154 5.852 5.852 0 0 0 3.69-1.154 8.339 8.339 0 0 0 2.45-3.084 17.274 17.274 0 0 0 1.36-4.558c.29-1.843.43-3.71.43-5.577V19.223a34.345 34.345 0 0 0-.56-5.54c-.23-1.575-.69-3.11-1.35-4.558a8.263 8.263 0 0 0-2.46-3.084 5.824 5.824 0 0 0-3.68-1.155 5.725 5.725 0 0 0-3.63 1.155 8.386 8.386 0 0 0-2.45 3.084c-.61 1.455-1 2.99-1.17 4.557a37.19 37.19 0 0 0-.4 5.578l-.04 32.948Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M1115.53 3.474c1.48-.043 2.93.36 4.18 1.154a8.199 8.199 0 0 1 2.76 3.231 16.94 16.94 0 0 1 1.51 4.914c.32 2.073.48 4.168.47 6.266v6.45c0 5.16-.79 8.967-2.37 11.424-1.58 2.457-4.09 3.686-7.52 3.686h-6.14v27.37h-1.35V3.475h8.46Zm-.98 35.196c3.03 0 5.21-1.028 6.55-3.083 1.33-2.056 2-5.39 2-10v-6.634a36.53 36.53 0 0 0-.37-5.43c-.18-1.485-.6-2.931-1.23-4.288a7.382 7.382 0 0 0-2.33-2.813 6.154 6.154 0 0 0-3.59-1.02h-7.13V38.67h6.1Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M1129.04 19.321c-.01-2.13.16-4.26.5-6.363.27-1.79.81-3.53 1.59-5.16a9.702 9.702 0 0 1 2.83-3.501 6.75 6.75 0 0 1 4.17-1.229c1.5-.06 2.99.372 4.22 1.229a9.655 9.655 0 0 1 2.85 3.501c.79 1.632 1.34 3.37 1.63 5.16.36 2.102.53 4.231.52 6.363v32.801c.01 2.133-.16 4.262-.52 6.364-.29 1.79-.84 3.527-1.63 5.16a9.645 9.645 0 0 1-2.85 3.5 6.91 6.91 0 0 1-4.22 1.23 6.748 6.748 0 0 1-4.17-1.23 9.693 9.693 0 0 1-2.83-3.5c-.78-1.63-1.32-3.37-1.59-5.16a38.757 38.757 0 0 1-.5-6.364v-32.8Zm1.36 32.887c-.01 1.866.13 3.731.4 5.578a16.46 16.46 0 0 0 1.33 4.557 8.388 8.388 0 0 0 2.45 3.084 5.702 5.702 0 0 0 3.63 1.154 5.824 5.824 0 0 0 3.68-1.154 8.265 8.265 0 0 0 2.46-3.084 16.888 16.888 0 0 0 1.35-4.558c.3-1.843.44-3.71.43-5.577V19.223a33.6 33.6 0 0 0-.43-5.577c-.23-1.575-.69-3.11-1.35-4.558a8.263 8.263 0 0 0-2.46-3.084 5.824 5.824 0 0 0-3.68-1.154 5.706 5.706 0 0 0-3.63 1.154 8.386 8.386 0 0 0-2.45 3.084 16.04 16.04 0 0 0-1.33 4.595c-.27 1.846-.41 3.71-.4 5.577v32.948Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M1160.8 3.473c3.11 0 5.27 1.15 6.49 3.452 1.21 2.301 1.81 5.602 1.8 9.901v4.153c.01 1.616-.07 3.232-.24 4.84a14.084 14.084 0 0 1-.92 3.784 7.627 7.627 0 0 1-1.81 2.666 5.994 5.994 0 0 1-2.97 1.425c1.19.228 2.31.759 3.24 1.541a7.096 7.096 0 0 1 2.06 2.93 24.58 24.58 0 0 1 1.52 9.263v5.713a23.46 23.46 0 0 1-2.13 10.823 7.269 7.269 0 0 1-2.88 3.049 7.299 7.299 0 0 1-4.09.956h-8.59V3.473h8.52Zm-1.66 29.3c1.3.025 2.59-.129 3.85-.455 1.06-.288 2-.898 2.7-1.745a8.177 8.177 0 0 0 1.57-3.55c.37-1.898.53-3.83.49-5.762v-4.336c.16-2.939-.34-5.875-1.45-8.6-.95-1.933-2.78-2.899-5.49-2.899h-7.24v27.359l5.57-.013Zm1.72 33.267a6.139 6.139 0 0 0 3.52-.781 6.18 6.18 0 0 0 2.48-2.622 21.067 21.067 0 0 0 1.74-9.496v-5.799c0-4.668-.74-7.944-2.21-9.828a7.93 7.93 0 0 0-2.97-2.237 7.858 7.858 0 0 0-3.66-.625h-6.21v31.425l7.31-.037Z"
              data-color="1"></path>
        <path fill="#2B2F30" d="M1175.54 17.109h-.67l.67-6.143V3.473h1.35v7.37l-1.35 6.266Z"
              data-color="1"></path>
        <path fill="#2B2F30"
              d="M1193.6 38.252c-1.29-.015-2.57.172-3.81.553a6.98 6.98 0 0 0-2.97 1.892 8.803 8.803 0 0 0-1.94 3.685 21.32 21.32 0 0 0-.68 5.897v10.086c0 1.416-.06 2.785-.18 4.103-.1 1.221-.39 2.42-.86 3.55h-1.47c.52-1.165.84-2.412.94-3.685.14-1.314.21-2.634.21-3.956V50.242c-.11-2.985.43-5.96 1.6-8.71a7.343 7.343 0 0 1 2.1-2.721 7.406 7.406 0 0 1 3.12-1.468 7.887 7.887 0 0 1-3.07-1.569 7.956 7.956 0 0 1-2.13-2.719 21.156 21.156 0 0 1-1.63-9.262v-5.86c-.16-3.628.53-7.243 2.02-10.553a7.043 7.043 0 0 1 2.82-2.996 7.054 7.054 0 0 1 4-.923h8.6v64.496h-1.35V38.252h-5.32Zm-1.84-32.8a5.998 5.998 0 0 0-5.86 3.316 21.102 21.102 0 0 0-1.68 9.312v5.897c-.06 2.066.16 4.131.63 6.142.34 1.35.97 2.607 1.85 3.686a6.02 6.02 0 0 0 2.95 1.892c1.28.352 2.62.522 3.95.503h5.28V5.402l-7.12.05Z"
              data-color="1"></path>
      </g>
    </svg>
    <svg class="self-medication__mob" preserveAspectRatio="none" data-bbox="-0.01 -0.002 528.56 136.862"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="-0.01 -0.002 528.56 136.862" height="137" width="529" data-type="color" role="presentation"
         aria-hidden="true" aria-label="">
      <g>
        <g clip-path="url(#3345f3e6-694a-4446-902c-334fead0c809_comp-lwz1zp212)">
          <path fill="#2B2F30"
                d="M8.91 0a6.72 6.72 0 0 1 4.15 1.32 9.39 9.39 0 0 1 2.78 3.48 18.771 18.771 0 0 1 1.55 5.17 38.7 38.7 0 0 1 .49 6.27v4.94h-1.35v-5a37.001 37.001 0 0 0-.4-5.54 16.998 16.998 0 0 0-1.28-4.53 8.09 8.09 0 0 0-2.35-3.07A5.57 5.57 0 0 0 8.96 1.9a5.66 5.66 0 0 0-3.6 1.14 8.09 8.09 0 0 0-2.34 3 16.9 16.9 0 0 0-1.28 4.53 36.82 36.82 0 0 0-.4 5.54v32.75a35.871 35.871 0 0 0 .4 5.46 16.89 16.89 0 0 0 1.28 4.48 8.09 8.09 0 0 0 2.35 3.07 5.66 5.66 0 0 0 3.6 1.14 5.57 5.57 0 0 0 3.54-1.14 8.09 8.09 0 0 0 2.35-3.07 16.997 16.997 0 0 0 1.28-4.48c.273-1.817.407-3.653.4-5.49v-7h1.34v7a38.7 38.7 0 0 1-.49 6.27 18.779 18.779 0 0 1-1.55 5.17 9.38 9.38 0 0 1-2.78 3.48 6.72 6.72 0 0 1-4.15 1.28 6.58 6.58 0 0 1-4.12-1.28 9.49 9.49 0 0 1-2.75-3.48A18.75 18.75 0 0 1 .48 55.1a38.59 38.59 0 0 1-.49-6.27V16.2a38.59 38.59 0 0 1 .49-6.27c.27-1.79.8-3.532 1.57-5.17a9.5 9.5 0 0 1 2.74-3.44A6.58 6.58 0 0 1 8.91 0Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="m26.17 49.41-2.5 15.1h-1.22L32.76.32h1.89L45.2 64.51h-1.34l-2.5-15.1H26.17Zm.24-1.83h14.7L33.68 2.74l-7.27 44.84Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M63.2 64.32h-1.53L51.12 3.48v61H49.9v-64h2.07l10.5 60.73L72.96.46h2.07v64h-1.34v-61L63.2 64.32Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M79.85 16.2c-.01-2.113.153-4.224.49-6.31a17.996 17.996 0 0 1 1.59-5.12 9.639 9.639 0 0 1 2.8-3.45A6.72 6.72 0 0 1 88.88 0a6.85 6.85 0 0 1 4.18 1.32A9.512 9.512 0 0 1 95.9 4.8a18.713 18.713 0 0 1 1.62 5.12c.357 2.084.53 4.196.52 6.31v32.54a36.237 36.237 0 0 1-.52 6.31 18.724 18.724 0 0 1-1.62 5.12 9.5 9.5 0 0 1-2.84 3.48 6.85 6.85 0 0 1-4.18 1.28 6.72 6.72 0 0 1-4.15-1.28 9.628 9.628 0 0 1-2.81-3.48 17.995 17.995 0 0 1-1.59-5.12 38.351 38.351 0 0 1-.49-6.31l.01-32.57Zm1.34 32.67a36.777 36.777 0 0 0 .4 5.54 16.207 16.207 0 0 0 1.31 4.53 8.369 8.369 0 0 0 2.38 3.05 5.66 5.66 0 0 0 3.6 1.14 5.79 5.79 0 0 0 3.63-1.14 8.22 8.22 0 0 0 2.41-3.06 16.91 16.91 0 0 0 1.36-4.53c.293-1.832.436-3.685.43-5.54V16.1a34.34 34.34 0 0 0-.43-5.54 16.894 16.894 0 0 0-1.34-4.53 8.231 8.231 0 0 0-2.41-3.07 5.8 5.8 0 0 0-3.65-1.13 5.67 5.67 0 0 0-3.6 1.14 8.379 8.379 0 0 0-2.38 3.07 16.196 16.196 0 0 0-1.31 4.53 36.804 36.804 0 0 0-.4 5.54v32.76Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="m110.9 2.38-1.22 46.66a209.79 209.79 0 0 1-.31 5.58 23.293 23.293 0 0 1-.82 4.94 8.518 8.518 0 0 1-2 3.57 5.002 5.002 0 0 1-3.57 1.46h-.43v-1.91a4.118 4.118 0 0 0 3.2-1.24 7.753 7.753 0 0 0 1.73-3.12c.387-1.42.612-2.88.67-4.35.08-1.613.14-3.243.18-4.89L109.61.49h14.82v64h-1.4V2.38H110.9Z"
                data-color="1"></path>
          <path fill="#2B2F30" d="M130.66.46v64h-1.38v-64h1.38Z" data-color="1"></path>
          <path fill="#2B2F30"
                d="m140.12 32.67-3.23 7.32v24.52h-1.34v-64h1.34V36.6L152.74.46h1.46l-13.23 30.28 14.31 33.77h-1.46l-13.7-31.84Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M169.28 54.26a49.752 49.752 0 0 1-1.07 4.3 12.202 12.202 0 0 1-1.46 3.25 6.086 6.086 0 0 1-2.13 2 6.471 6.471 0 0 1-3.14.69v-1.82a4.901 4.901 0 0 0 2.53-.59 5.388 5.388 0 0 0 1.74-1.69c.53-.821.941-1.713 1.22-2.65.326-1.04.66-2.2 1-3.48l2.44-10.43L159.76.46h1.4l9.88 40.72 4.09-18L180.28.46h1.46l-12.46 53.8Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M194.96.46c3.087 0 5.23 1.143 6.43 3.43 1.2 2.287 1.8 5.567 1.8 9.84v4.12c.01 1.603-.07 3.206-.24 4.8a13.703 13.703 0 0 1-.92 3.75 7.538 7.538 0 0 1-1.8 2.65 5.928 5.928 0 0 1-3 1.42 6.997 6.997 0 0 1 5.28 4.44 24.268 24.268 0 0 1 1.5 9.2v5.67c0 4.513-.7 8.097-2.1 10.75a7.234 7.234 0 0 1-6.92 4h-8.6v-64l8.57-.07Zm-1.68 29.09a14.071 14.071 0 0 0 3.81-.46 5.29 5.29 0 0 0 2.71-1.77 8.138 8.138 0 0 0 1.56-3.52c.362-1.884.526-3.802.49-5.72v-4.26c0-3.78-.477-6.633-1.43-8.56-.953-1.927-2.773-2.887-5.46-2.88h-7.2v27.17h5.52Zm1.71 33a6.142 6.142 0 0 0 5.95-3.38c1.16-2.253 1.74-5.393 1.74-9.42v-5.74c0-4.667-.733-7.93-2.2-9.79-1.467-1.86-3.663-2.807-6.59-2.84h-6.16v31.2l7.26-.03Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="m212.34 49.41-2.5 15.1h-1.22L218.93.32h1.89l10.55 64.14h-1.34l-2.5-15.1-15.19.05Zm.24-1.83h14.7l-7.44-44.84-7.26 44.84Z"
                data-color="1"></path>
          <path fill="#2B2F30" d="M237.41 33.4v31.11h-1.34v-64h1.34v31.06h15.43V.46h1.34v64h-1.34V33.4h-15.43Z"
                data-color="1"></path>
          <path fill="#2B2F30" d="M260.41 33.4v31.11h-1.34v-64h1.34v31.06h15.43V.46h1.34v64h-1.34V33.4h-15.43Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M293.78 34.95a12.3 12.3 0 0 0-3.78.55 6.92 6.92 0 0 0-3 1.88 8.785 8.785 0 0 0-1.92 3.61 21.243 21.243 0 0 0-.67 5.86v10.06c0 1.407-.06 2.763-.18 4.07a11.525 11.525 0 0 1-.85 3.52h-1.46a11.25 11.25 0 0 0 .95-3.66c.136-1.306.206-2.617.21-3.93V46.85a20.225 20.225 0 0 1 1.59-8.65 7.259 7.259 0 0 1 5.18-4.16 7.925 7.925 0 0 1-5.15-4.25c-1.08-2.167-1.62-5.233-1.62-9.2v-5.77c0-4.393.667-7.887 2-10.48a6.995 6.995 0 0 1 6.77-3.89h8.48v64h-1.34v-29.5h-5.21Zm-1.83-32.57a5.997 5.997 0 0 0-5.83 3.38 21.104 21.104 0 0 0-1.68 9.24v5.86a23.61 23.61 0 0 0 .64 6.08 9.367 9.367 0 0 0 1.83 3.71 5.998 5.998 0 0 0 2.93 1.88 14.1 14.1 0 0 0 3.93.5h5.25V2.38h-7.07Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M328 64.32h-1.53L315.92 3.48v61h-1.22v-64h2.07l10.51 60.73L337.76.46h2.07v64h-1.34v-61L328 64.32Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M344.65 16.2a38.32 38.32 0 0 1 .49-6.31 18.005 18.005 0 0 1 1.59-5.12 9.643 9.643 0 0 1 2.8-3.45 6.72 6.72 0 0 1 4.15-1.28 6.85 6.85 0 0 1 4.18 1.28 9.508 9.508 0 0 1 2.84 3.48 18.708 18.708 0 0 1 1.58 5.08c.357 2.084.531 4.195.52 6.31v32.58a36.256 36.256 0 0 1-.52 6.31 18.724 18.724 0 0 1-1.62 5.12 9.504 9.504 0 0 1-2.84 3.48 6.849 6.849 0 0 1-4.18 1.28 6.72 6.72 0 0 1-4.15-1.28 9.633 9.633 0 0 1-2.81-3.48 18.005 18.005 0 0 1-1.59-5.12 38.318 38.318 0 0 1-.49-6.31l.05-32.57Zm1.34 32.67a36.816 36.816 0 0 0 .4 5.54 16.23 16.23 0 0 0 1.31 4.53 8.37 8.37 0 0 0 2.38 3.06 5.66 5.66 0 0 0 3.6 1.14 5.789 5.789 0 0 0 3.6-1.15 8.213 8.213 0 0 0 2.41-3.06 16.87 16.87 0 0 0 1.34-4.53 34.26 34.26 0 0 0 .43-5.54V16.1a34.26 34.26 0 0 0-.43-5.54 16.87 16.87 0 0 0-1.34-4.53 8.227 8.227 0 0 0-2.41-3.06 5.799 5.799 0 0 0-3.63-1.14 5.671 5.671 0 0 0-3.6 1.14 8.379 8.379 0 0 0-2.38 3.07 16.196 16.196 0 0 0-1.31 4.53 36.817 36.817 0 0 0-.4 5.54l.03 32.76Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="m388.38 31.11-3.35 8.14v25.26h-1.34V39.25l-3.23-8.14-11.65 33.4h-1.4l12.19-35.19L368.07.46h1.4l11.6 28.86 2.62 6.77V.46h1.34v35.59l2.68-7L399.28.46h1.46l-11.46 28.86 12.1 35.19h-1.4l-11.6-33.4Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M419.98 31.11v1.83h-12.56v29.65h14.94v1.92h-16.29v-64h16.29v1.87h-14.94v28.73h12.56Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M444.81 28.32c3.293 0 5.763 1 7.41 3 1.646 2 2.47 5.57 2.47 10.71v7.41c0 4.76-.71 8.45-2.13 11.07-1.42 2.62-3.78 3.93-7.08 3.93h-8.91v-64h16.65v1.94h-15.3v25.94h6.89Zm.67 34.22c2.886 0 4.92-1.127 6.1-3.38 1.18-2.253 1.77-5.457 1.77-9.61V42c0-4.573-.71-7.67-2.13-9.29-1.42-1.62-3.557-2.417-6.41-2.39h-6.89v32.3l7.56-.08Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M468.78 54.26a49.752 49.752 0 0 1-1.07 4.3 12.231 12.231 0 0 1-1.43 3.25 6.086 6.086 0 0 1-2.13 2 6.471 6.471 0 0 1-3.14.69v-1.82a4.901 4.901 0 0 0 2.53-.59 5.39 5.39 0 0 0 1.74-1.7c.53-.821.941-1.713 1.22-2.65.326-1.04.66-2.2 1-3.48l2.44-10.43L459.28.46h1.4l9.88 40.72 4.09-18L479.83.49h1.45l-12.5 53.77Z"
                data-color="1"></path>
          <path fill="#2B2F30" d="M495.01 64.51V2.38h-9.33V.46h20v1.92h-9.33v62.13h-1.34Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="m519.47 34.13-7.5 30.38h-1.69v-64h1.28v60.15L526.67.46h1.83v64h-1.28V3.57l-7.75 30.56Z"
                data-color="1"></path>
          <path fill="#2B2F30" d="M1.14 77.01v50.92h10.14V77.01h1.1v50.92h10.1V77.01h1.1v52.5H.04v-52.5h1.1Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="m31.28 103.41-2.65 6v20.1h-1.1v-52.5h1.1v29.63l13-29.63h1.2l-10.84 24.82 11.75 27.67h-1.2l-11.26-26.09Z"
                data-color="1"></path>
          <path fill="#2B2F30" d="M48.68 77.01v52.5h-1.1v-52.5h1.1Z" data-color="1"></path>
          <path fill="#2B2F30"
                d="M72.18 136.86v-7.35H53.63v7.35h-1.1v-8.92h2.5a9.341 9.341 0 0 0 1.45-4c.2-1.547.333-3.213.4-5l1.45-41.92h11.85v50.92h3.1v8.92h-1.1Zm-14.2-17.85a67.635 67.635 0 0 1-.35 4.88 9.73 9.73 0 0 1-1.35 4h12.8V78.58h-9.7l-1.4 40.43Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="m83.78 78.58-1 38.25c-.067 1.547-.15 3.073-.25 4.58a18.953 18.953 0 0 1-.67 4 6.996 6.996 0 0 1-1.6 2.92 4.1 4.1 0 0 1-2.92 1.2h-.36v-1.58a3.389 3.389 0 0 0 2.63-1 6.354 6.354 0 0 0 1.38-2.59 15.91 15.91 0 0 0 .55-3.56 157.2 157.2 0 0 0 .15-4l1-39.83h12.19v52.5h-1.15V78.58h-9.95Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="m106.38 104.61-6.1 24.9h-1.4v-52.5h1v49.31l12.4-49.31h1.5v52.5h-1.05v-50l-6.35 25.1Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M124.78 77.01c2.533 0 4.293.937 5.28 2.81.987 1.873 1.477 4.56 1.47 8.06v3.38a35.275 35.275 0 0 1-.2 3.94 11.277 11.277 0 0 1-.75 3.08 6.2 6.2 0 0 1-1.47 2.17 4.865 4.865 0 0 1-2.42 1.16 5.78 5.78 0 0 1 2.636 1.255 5.783 5.783 0 0 1 1.684 2.385 19.898 19.898 0 0 1 1.23 7.54v4.65a19.006 19.006 0 0 1-1.73 8.81 5.927 5.927 0 0 1-5.67 3.26h-7v-52.5h6.94Zm-1.35 23.85a11.516 11.516 0 0 0 3.13-.37 4.321 4.321 0 0 0 2.2-1.42 6.671 6.671 0 0 0 1.28-2.89c.296-1.545.43-3.117.4-4.69v-3.53a16.323 16.323 0 0 0-1.18-7c-.78-1.573-2.27-2.36-4.47-2.36h-5.9v22.27l4.54-.01Zm1.4 27.08a5.002 5.002 0 0 0 4.88-2.77 17.16 17.16 0 0 0 1.42-7.73v-4.72c0-3.8-.6-6.467-1.8-8a6.413 6.413 0 0 0-5.4-2.33h-5.05v25.58l5.95-.03Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="m143.63 104.61-6.15 24.9h-1.35v-52.5h1v49.31l12.35-49.35h1.5v52.5h-1v-50l-6.35 25.14Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M165.93 129.32h-1.25l-8.65-49.87v50h-1V77.01h1.7l8.6 49.8 8.6-49.8h1.7v52.5h-1.1v-50l-8.6 49.81Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M206.88 136.86v-7.35h-18.55v7.35h-1.05v-8.92h2.5a9.347 9.347 0 0 0 1.45-4c.2-1.547.333-3.213.4-5l1.45-41.92h11.8v50.92h3.1v8.92h-1.1Zm-14.2-17.85a68.212 68.212 0 0 1-.35 4.88 9.735 9.735 0 0 1-1.35 4h12.8V78.58h-9.7l-1.4 40.43Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="m218.48 78.58-1 38.25c-.067 1.547-.15 3.073-.25 4.58a18.972 18.972 0 0 1-.67 4 7.004 7.004 0 0 1-1.6 2.92 4.1 4.1 0 0 1-2.92 1.2h-.35v-1.58a3.39 3.39 0 0 0 2.63-1 6.359 6.359 0 0 0 1.38-2.59c.317-1.162.502-2.356.55-3.56a157.2 157.2 0 0 0 .15-4l1-39.83h12.18v52.5h-1.15V78.58h-9.95Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M243.18 105.32c-1.05-.012-2.096.14-3.1.45a5.668 5.668 0 0 0-2.42 1.54 7.193 7.193 0 0 0-1.58 3 17.417 17.417 0 0 0-.55 4.8v8.21c0 1.153-.05 2.267-.15 3.34a9.421 9.421 0 0 1-.7 2.89h-1.2a9.227 9.227 0 0 0 .77-3c.11-1.07.167-2.144.17-3.22v-8.25a16.578 16.578 0 0 1 1.3-7.09 5.995 5.995 0 0 1 4.25-3.41 6.493 6.493 0 0 1-4.23-3.49 17.274 17.274 0 0 1-1.33-7.54v-4.77a18.823 18.823 0 0 1 1.65-8.59 5.753 5.753 0 0 1 5.55-3.19h7v52.5h-1.1v-24.18h-4.33Zm-1.5-26.7a4.89 4.89 0 0 0-4.77 2.7 17.29 17.29 0 0 0-1.37 7.58v4.8a19.43 19.43 0 0 0 .52 5 7.7 7.7 0 0 0 1.5 3 4.915 4.915 0 0 0 2.4 1.54c1.049.287 2.133.425 3.22.41h4.3V78.58l-5.8.04Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M267.28 77.01c2.533 0 4.293.937 5.28 2.81.987 1.873 1.477 4.56 1.47 8.06v3.38a35.275 35.275 0 0 1-.2 3.94 11.277 11.277 0 0 1-.75 3.08 6.2 6.2 0 0 1-1.47 2.17 4.865 4.865 0 0 1-2.42 1.16 5.78 5.78 0 0 1 2.636 1.255 5.783 5.783 0 0 1 1.684 2.385 19.898 19.898 0 0 1 1.23 7.54v4.65a19.006 19.006 0 0 1-1.73 8.81 5.927 5.927 0 0 1-5.67 3.26h-7v-52.5h6.94Zm-1.35 23.85a11.516 11.516 0 0 0 3.13-.37 4.321 4.321 0 0 0 2.2-1.42 6.671 6.671 0 0 0 1.28-2.89c.296-1.545.43-3.117.4-4.69v-3.53a16.323 16.323 0 0 0-1.18-7c-.78-1.573-2.27-2.36-4.47-2.36h-5.9v22.27l4.54-.01Zm1.4 27.08a5.002 5.002 0 0 0 4.88-2.77 17.16 17.16 0 0 0 1.42-7.73v-4.72c0-3.8-.6-6.467-1.8-8a6.413 6.413 0 0 0-5.4-2.33h-5.05v25.58l5.95-.03Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="m281.58 117.13-2.05 12.37h-1l8.45-52.58h1.55l8.65 52.58h-1.1l-2.05-12.37h-12.45Zm.2-1.5h12.05l-6.1-36.75-5.95 36.75Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M302.13 77.01v50.92h10.1V77.01h1.05v50.92h10.1V77.01h1.1v52.5h-23.5v-52.5h1.15Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M328.48 89.91a31.53 31.53 0 0 1 .4-5.18 14.74 14.74 0 0 1 1.3-4.2 7.905 7.905 0 0 1 2.3-2.85 5.508 5.508 0 0 1 3.4-1 5.62 5.62 0 0 1 3.43 1 7.796 7.796 0 0 1 2.32 2.85 15.343 15.343 0 0 1 1.33 4.2 29.77 29.77 0 0 1 .42 5.18v26.7a29.766 29.766 0 0 1-.42 5.18 15.346 15.346 0 0 1-1.33 4.2 7.778 7.778 0 0 1-2.32 2.85 5.614 5.614 0 0 1-3.43 1 5.508 5.508 0 0 1-3.4-1 7.879 7.879 0 0 1-2.3-2.85 14.738 14.738 0 0 1-1.3-4.2 31.53 31.53 0 0 1-.4-5.18v-26.7Zm1.1 26.77a30.262 30.262 0 0 0 .33 4.54 13.265 13.265 0 0 0 1.08 3.71 6.862 6.862 0 0 0 2 2.51 4.638 4.638 0 0 0 2.95.94 4.75 4.75 0 0 0 3-.94 6.75 6.75 0 0 0 2-2.51 13.858 13.858 0 0 0 1.1-3.71c.239-1.501.356-3.02.35-4.54V89.83a28.172 28.172 0 0 0-.35-4.54 13.859 13.859 0 0 0-1.1-3.71 6.77 6.77 0 0 0-2-2.51 4.749 4.749 0 0 0-3-.94 4.64 4.64 0 0 0-2.95.94 6.86 6.86 0 0 0-2 2.51 13.236 13.236 0 0 0-1.09 3.74 30.256 30.256 0 0 0-.33 4.54l.01 26.82Z"
                data-color="1"></path>
          <path fill="#2B2F30" d="M348.43 78.58v50.92h-1.15V77.01h13v1.58l-11.85-.01Z" data-color="1"></path>
          <path fill="#2B2F30"
                d="M364.23 89.91a31.53 31.53 0 0 1 .4-5.18 14.74 14.74 0 0 1 1.3-4.2 7.905 7.905 0 0 1 2.3-2.85 5.508 5.508 0 0 1 3.4-1 5.62 5.62 0 0 1 3.43 1 7.796 7.796 0 0 1 2.32 2.85 15.343 15.343 0 0 1 1.33 4.2 29.77 29.77 0 0 1 .42 5.18v26.7a29.766 29.766 0 0 1-.42 5.18 15.346 15.346 0 0 1-1.33 4.2 7.778 7.778 0 0 1-2.32 2.85 5.614 5.614 0 0 1-3.43 1 5.508 5.508 0 0 1-3.4-1 7.879 7.879 0 0 1-2.3-2.85 14.738 14.738 0 0 1-1.3-4.2 31.53 31.53 0 0 1-.4-5.18v-26.7Zm1.1 26.77a30.262 30.262 0 0 0 .33 4.54 13.265 13.265 0 0 0 1.08 3.71 6.862 6.862 0 0 0 2 2.51 4.638 4.638 0 0 0 2.95.94 4.75 4.75 0 0 0 3-.94 6.75 6.75 0 0 0 2-2.51 13.858 13.858 0 0 0 1.1-3.71c.239-1.501.356-3.02.35-4.54V89.83a28.209 28.209 0 0 0-.46-4.51 13.859 13.859 0 0 0-1.1-3.71 6.77 6.77 0 0 0-2-2.51 4.749 4.749 0 0 0-3-.94 4.64 4.64 0 0 0-2.95.94 6.86 6.86 0 0 0-2 2.51 13.252 13.252 0 0 0-.98 3.71 30.256 30.256 0 0 0-.33 4.54l.01 26.82Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M398.13 76.63a5.508 5.508 0 0 1 3.4 1 7.482 7.482 0 0 1 2.25 2.85 16.01 16.01 0 0 1 1.25 4.2 31.53 31.53 0 0 1 .4 5.18v1.35a16.192 16.192 0 0 1-1.35 6.79 7.498 7.498 0 0 1-3.75 4 6.546 6.546 0 0 1 3.83 3.79 16.481 16.481 0 0 1 1.27 6.71v4a31.53 31.53 0 0 1-.4 5.18 16.005 16.005 0 0 1-1.25 4.2 7.462 7.462 0 0 1-2.25 2.85 5.999 5.999 0 0 1-6.8 0 7.676 7.676 0 0 1-2.28-2.85 15.33 15.33 0 0 1-1.28-4.2 31.53 31.53 0 0 1-.4-5.18v-3.82h1.1v3.9a30.262 30.262 0 0 0 .33 4.54 13.84 13.84 0 0 0 1 3.71 6.753 6.753 0 0 0 1.9 2.51 4.53 4.53 0 0 0 2.92.94 4.64 4.64 0 0 0 3-.94 6.577 6.577 0 0 0 1.92-2.55 14.46 14.46 0 0 0 1-3.75c.224-1.503.334-3.021.33-4.54v-4a13.43 13.43 0 0 0-1.62-6.94 5 5 0 0 0-4.42-2.74h-2.25v-1.5h2.3a3.91 3.91 0 0 0 2.4-.83 7.362 7.362 0 0 0 1.9-2.17c.567-.984.988-2.045 1.25-3.15.303-1.227.454-2.486.45-3.75v-1.51a30.656 30.656 0 0 0-.33-4.61 13.881 13.881 0 0 0-1-3.71 6.653 6.653 0 0 0-1.92-2.51 5.11 5.11 0 0 0-5.9 0 6.64 6.64 0 0 0-1.92 2.51 13.84 13.84 0 0 0-1 3.71 29.994 29.994 0 0 0-.33 4.54v2.25h-1v-2.26a30.892 30.892 0 0 1 .4-5.1 15.888 15.888 0 0 1 1.25-4.2 7.465 7.465 0 0 1 2.25-2.85 5.51 5.51 0 0 1 3.35-1.05Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M428.88 136.86v-7.35h-18.6v7.35h-1.1v-8.92h2.5a9.334 9.334 0 0 0 1.45-4c.2-1.547.334-3.213.4-5l1.45-41.92h11.9v50.92h3.1v8.92h-1.1Zm-14.2-17.85a67.068 67.068 0 0 1-.35 4.88 9.72 9.72 0 0 1-1.35 4h12.8V78.58h-9.7l-1.4 40.43Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M433.82 89.91a31.53 31.53 0 0 1 .4-5.18 14.74 14.74 0 0 1 1.3-4.2 7.895 7.895 0 0 1 2.3-2.85 5.508 5.508 0 0 1 3.4-1 5.621 5.621 0 0 1 3.43 1 7.796 7.796 0 0 1 2.32 2.85 15.343 15.343 0 0 1 1.33 4.2 29.77 29.77 0 0 1 .42 5.18v26.7a29.766 29.766 0 0 1-.42 5.18 15.346 15.346 0 0 1-1.33 4.2 7.778 7.778 0 0 1-2.32 2.85 5.616 5.616 0 0 1-3.43 1 5.508 5.508 0 0 1-3.4-1 7.879 7.879 0 0 1-2.3-2.85 14.738 14.738 0 0 1-1.3-4.2 31.42 31.42 0 0 1-.4-5.18v-26.7Zm1.1 26.77a30.13 30.13 0 0 0 .33 4.54 13.265 13.265 0 0 0 1.08 3.71 6.862 6.862 0 0 0 2 2.51 4.636 4.636 0 0 0 2.95.94 4.751 4.751 0 0 0 3-.94 6.75 6.75 0 0 0 2-2.51 13.894 13.894 0 0 0 1.1-3.71c.239-1.501.356-3.02.35-4.54V89.83a28.2 28.2 0 0 0-.45-4.51 13.895 13.895 0 0 0-1.1-3.71 6.77 6.77 0 0 0-2-2.51 4.752 4.752 0 0 0-3-.94 4.64 4.64 0 0 0-2.95.94 6.86 6.86 0 0 0-2 2.51 13.228 13.228 0 0 0-.95 3.71 30.125 30.125 0 0 0-.33 4.54l-.03 26.82Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M459.57 77.01a6 6 0 0 1 3.4.94 6.714 6.714 0 0 1 2.25 2.63 13.79 13.79 0 0 1 1.23 4c.262 1.687.389 3.393.38 5.1v5.25c0 4.2-.643 7.3-1.93 9.3-1.286 2-3.326 3-6.12 3h-5v22.28h-1.1v-52.5h6.89Zm-.8 28.65c2.467 0 4.244-.837 5.33-2.51 1.087-1.673 1.63-4.387 1.63-8.14v-5.4a30.258 30.258 0 0 0-.3-4.42 11.705 11.705 0 0 0-1-3.49 5.996 5.996 0 0 0-1.9-2.29 5 5 0 0 0-2.92-.83h-5.8v27.08h4.96Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M470.57 89.91a31.53 31.53 0 0 1 .4-5.18 14.74 14.74 0 0 1 1.3-4.2 7.895 7.895 0 0 1 2.3-2.85 5.508 5.508 0 0 1 3.4-1 5.621 5.621 0 0 1 3.43 1 7.796 7.796 0 0 1 2.32 2.85 15.343 15.343 0 0 1 1.33 4.2 29.77 29.77 0 0 1 .42 5.18v26.7a29.766 29.766 0 0 1-.42 5.18 15.346 15.346 0 0 1-1.33 4.2 7.778 7.778 0 0 1-2.32 2.85 5.616 5.616 0 0 1-3.43 1 5.508 5.508 0 0 1-3.4-1 7.879 7.879 0 0 1-2.3-2.85 14.738 14.738 0 0 1-1.3-4.2 31.42 31.42 0 0 1-.4-5.18v-26.7Zm1.1 26.77a30.13 30.13 0 0 0 .33 4.54 13.265 13.265 0 0 0 1.08 3.71 6.862 6.862 0 0 0 2 2.51 4.636 4.636 0 0 0 2.95.94 4.751 4.751 0 0 0 3-.94 6.75 6.75 0 0 0 2-2.51 13.894 13.894 0 0 0 1.1-3.71c.239-1.501.356-3.02.35-4.54V89.83a28.172 28.172 0 0 0-.35-4.54 13.895 13.895 0 0 0-1.1-3.71 6.77 6.77 0 0 0-2-2.51 4.75 4.75 0 0 0-3-.94 4.639 4.639 0 0 0-2.95.94 6.86 6.86 0 0 0-2 2.51 13.26 13.26 0 0 0-1.08 3.74 30.125 30.125 0 0 0-.33 4.54v26.82Z"
                data-color="1"></path>
          <path fill="#2B2F30"
                d="M496.42 77.01c2.533 0 4.293.937 5.28 2.81.987 1.873 1.477 4.56 1.47 8.06v3.38a35.275 35.275 0 0 1-.2 3.94 11.24 11.24 0 0 1-.75 3.08 6.2 6.2 0 0 1-1.47 2.17 4.861 4.861 0 0 1-2.42 1.16 5.78 5.78 0 0 1 2.636 1.255 5.783 5.783 0 0 1 1.684 2.385 19.898 19.898 0 0 1 1.23 7.54v4.65a19.006 19.006 0 0 1-1.73 8.81 5.927 5.927 0 0 1-5.67 3.26h-7v-52.5h6.94Zm-1.35 23.85a11.512 11.512 0 0 0 3.13-.37 4.317 4.317 0 0 0 2.2-1.42 6.671 6.671 0 0 0 1.28-2.89c.296-1.545.43-3.117.4-4.69v-3.53a16.323 16.323 0 0 0-1.18-7c-.78-1.573-2.27-2.36-4.47-2.36h-5.9v22.27l4.54-.01Zm1.4 27.08a5.002 5.002 0 0 0 4.88-2.77 17.16 17.16 0 0 0 1.42-7.73v-4.72c0-3.8-.6-6.467-1.8-8a6.413 6.413 0 0 0-5.4-2.33h-5.05v25.58l5.95-.03Z"
                data-color="1"></path>
          <path fill="#2B2F30" d="M508.42 88.11h-.55l.55-5v-6.1h1.1v6l-1.1 5.1Z" data-color="1"></path>
          <path fill="#2B2F30"
                d="M523.12 105.32a10.112 10.112 0 0 0-3.1.45 5.668 5.668 0 0 0-2.42 1.54 7.193 7.193 0 0 0-1.58 3 17.379 17.379 0 0 0-.55 4.8v8.21c0 1.153-.05 2.267-.15 3.34a9.421 9.421 0 0 1-.7 2.89h-1.2a9.227 9.227 0 0 0 .77-3c.11-1.07.167-2.144.17-3.22v-8.25a16.578 16.578 0 0 1 1.3-7.09 6.003 6.003 0 0 1 4.25-3.41 6.494 6.494 0 0 1-4.23-3.49 17.274 17.274 0 0 1-1.33-7.54v-4.77a18.823 18.823 0 0 1 1.65-8.59 5.753 5.753 0 0 1 5.55-3.19h7v52.5h-1.1v-24.18h-4.33Zm-1.5-26.7a4.89 4.89 0 0 0-4.77 2.7 17.275 17.275 0 0 0-1.37 7.58v4.8c-.044 1.682.13 3.363.52 5a7.7 7.7 0 0 0 1.5 3 4.915 4.915 0 0 0 2.4 1.54c1.049.287 2.133.425 3.22.41h4.3V78.58l-5.8.04Z"
                data-color="1"></path>
        </g>
        <defs fill="none">
          <clipPath id="3345f3e6-694a-4446-902c-334fead0c809_comp-lwz1zp212">
            <path fill="#ffffff" d="M528.52 0v136.86H0V0h528.52z"></path>
          </clipPath>
        </defs>
      </g>
    </svg>
  </div>

</template>

<style scoped lang="scss">
.self-medication {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  height: 15vh;
  background: white;
  z-index: 999999999;

  display: flex;
  align-items: center;
  padding: 10px;

  &__desk {
    width: 100%;
    height: 100%;
    display: block;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &__mob {
    display: none;

    width: 100%;
    height: 100%;

    @media screen and (max-width: 1024px) {
      display: block;
    }
  }
}
</style>